const weekdayDateMap = {
    monday: new Date('2024-06-24T00:00:00.000Z'),
    tuesday: new Date('2024-06-25T00:00:00.000Z'),
    wednesday: new Date('2024-06-26T00:00:00.000Z'),
    thursday: new Date('2024-06-27T00:00:00.000Z'),
    friday: new Date('2024-06-28T00:00:00.000Z'),
    saturday: new Date('2024-06-29T00:00:00.000Z'),
    sunday: new Date('2024-06-30T00:00:00.000Z'),
};

export function getWeekday(weekday, locale, format = 'short') {
    const formatter = new Intl.DateTimeFormat(locale, {
        weekday: format,
    });

    return weekdayDateMap[weekday]
        ? { value: weekday, label: formatter.format(weekdayDateMap[weekday]) }
        : null;
}

export function getWeekdays(locale, format = 'short') {
    return [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ].map((day) => getWeekday(day, locale, format));
}
