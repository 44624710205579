<template>
    <multi-select-dropdown
        v-if="users.length > 1"
        :label="__('activities.overview.cell.user')"
        :model-value="modelValue"
        :options="users"
        :show-searchbar="true"
        :show-select-all-button="false"
        @update:model-value="$emit('update:modelValue', $event)"
    >
        <template v-slot:label="{ option, show }">
            <truncated-label :show="show" :text="option.label" />
        </template>
    </multi-select-dropdown>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import TruncatedLabel from '@/Pages/WorkOrders/TruncatedLabel.vue';

export default {
    name: 'user-filter',
    components: { MultiSelectDropdown, TruncatedLabel },
    props: {
        modelValue: [Array],
    },
    computed: {
        users() {
            return (
                this.$page.props.users?.data.map(
                    ({ firstName, lastName, id }) => ({
                        label: [firstName, lastName].join(' '),
                        value: id,
                    }),
                ) || []
            );
        },
    },
};
</script>
