<template>
    <div v-if="on" class="small text-success">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'JetActionMessage',

    props: ['on'],
};
</script>
