<template>
    <div class="d-flex overflow-hidden">
        <div
            class="d-flex justify-content-center align-items-center flex-shrink-0"
            style="width: 40px"
        >
            <wrench />
        </div>
        <div class="overflow-hidden">
            <div>
                <b class="text-truncate d-block">{{ title }}</b>
            </div>
            <small class="text-truncate d-block text-muted">{{
                description
            }}</small>
        </div>
    </div>
</template>
<script>
import Wrench from '../../../../icons/Wrench.vue';

export default {
    name: 'WorkOrderNotification',
    components: { Wrench },
    props: {
        notification: {},
    },
    computed: {
        title() {
            return this.notification.payload.workorder_code;
        },
        description() {
            return this.__(`notification.${this.notification.type}`);
        },
    },
};
</script>
