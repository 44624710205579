<template>
    <div>
        <div v-if="status?.decommissionedAt && showDecommissioned">
            <span>
                {{ __('general.status.decommissioned') }}
                <span class="text-nowrap">{{
                    formatDate(status?.decommissionedAt)
                }}</span>
            </span>
        </div>
        <div v-else>
            <span>
                {{ __('general.status.last_checked') }}
                <span
                    :class="{ 'text-danger': status?.isCheckOverdue }"
                    class="text-nowrap"
                    >{{ formatDate(status?.operabilityLastCheckedAt) }}</span
                >
            </span>
        </div>
    </div>
</template>
<script>
import { DateTime } from 'luxon';

export default {
    props: {
        status: {},
        showDecommissioned: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        formatDate(date) {
            let dateObj = DateTime.fromISO(date);

            // Check if date is valid
            if (!dateObj || !dateObj.isValid) {
                return this.__('general.not_available');
            }

            dateObj = dateObj.setLocale(this.currentLocale);

            if (dateObj.hasSame(DateTime.local(), 'day')) {
                return (
                    this.__('general.today') +
                    ', ' +
                    dateObj.toLocaleString(DateTime.TIME_24_SIMPLE)
                );
            } else {
                return dateObj.toLocaleString(DateTime.DATETIME_MED);
            }
        },
    },
};
</script>
