export function getLabelForLocale(locale: string): string {
    switch (locale) {
        case 'en':
            return 'English';
        case 'fr':
            return 'Français';
        case 'ar':
            return 'العربية';
        default:
            return locale;
    }
}
