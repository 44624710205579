<template>
    <jet-form-section>
        <template #title>
            {{ __('profile.information.browser_session.header') }}
        </template>

        <template #form>
            <p class="mb-3">
                {{ __('profile.information.browser_session.description') }}
            </p>
            {{ __('profile.information.browser_session.content') }}

            <!-- Other Browser Sessions -->
            <CListGroup v-if="sessions.length > 0" class="mt-5">
                <CListGroupItem
                    v-for="(session, i) in sessions"
                    :key="i"
                    class="d-flex justify-content-start align-items-center"
                >
                    <div style="width: 2rem">
                        <icon
                            v-if="session.agent.is_desktop"
                            icon="cil-screen-desktop"
                            size="xl"
                        />

                        <icon v-else icon="cil-mobile" size="xl" />
                    </div>

                    <div class="ml-3">
                        <strong>
                            {{ session.agent.platform }} -
                            {{ session.agent.browser }}
                        </strong>

                        <div>
                            <div class="small">
                                {{ session.ip_address }},

                                <b
                                    v-if="session.is_current_device"
                                    class="text-success font-semibold"
                                    >{{
                                        __(
                                            'profile.information.browser_session.current_device',
                                        )
                                    }}</b
                                >
                                <span v-else>
                                    {{
                                        __(
                                            'profile.information.browser_session.last_active',
                                            { date: session.last_active },
                                        )
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </CListGroupItem>
            </CListGroup>

            <div class="d-flex justify-content-center mt-5">
                <jet-action-message :on="form.recentlySuccessful" class="ml-3">
                    {{
                        __(
                            'profile.information.browser_session.message.success',
                        )
                    }}
                </jet-action-message>
                <jet-button @click="confirmLogout">
                    {{
                        __('profile.information.browser_session.action.logout')
                    }}
                </jet-button>
            </div>

            <!-- Log Out Other Devices Confirmation Modal -->
            <dialog-modal :show="confirmingLogout" @close="closeModal">
                <template #title>
                    {{
                        __(
                            'profile.information.browser_session.modal.browser_session.title',
                        )
                    }}
                </template>

                {{
                    __(
                        'profile.information.browser_session.modal.browser_session.content',
                    )
                }}

                <div class="mt-4">
                    <jet-input
                        ref="password"
                        v-model="form.password"
                        :invalidFeedback="form.errors.password"
                        class="mt-1 block w-3/4"
                        placeholder="Password"
                        type="password"
                        @keyup.enter="logoutOtherBrowserSessions"
                    />
                </div>

                <template #footer>
                    <jet-button color="secondary" @click="closeModal">
                        {{ __('general.action.cancel') }}
                    </jet-button>

                    <jet-button
                        :disabled="form.processing"
                        class="ml-2"
                        @click="logoutOtherBrowserSessions"
                    >
                        {{
                            __(
                                'profile.information.browser_session.action.logout',
                            )
                        }}
                    </jet-button>
                </template>
            </dialog-modal>
        </template>
    </jet-form-section>
</template>

<script>
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';

export default {
    props: ['sessions'],

    components: {
        JetActionMessage,
        JetFormSection,
        JetInput,
        JetInputError,
        DialogModal,
    },

    data() {
        return {
            confirmingLogout: false,

            form: this.$inertia.form({
                password: '',
            }),
        };
    },

    methods: {
        confirmLogout() {
            this.confirmingLogout = true;

            setTimeout(() => this.$refs.password.focus(), 250);
        },

        logoutOtherBrowserSessions() {
            this.form.delete(route('other-browser-sessions.destroy'), {
                preserveScroll: true,
                onSuccess: () => this.closeModal(),
                onError: () => this.$refs.password.focus(),
                onFinish: () => this.form.reset(),
            });
        },

        closeModal() {
            this.confirmingLogout = false;

            this.form.reset();
        },
    },
};
</script>
