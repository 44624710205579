import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const useSidebarStore = defineStore('sidebar', () => {
    const visible = useLocalStorage(
        'sidebar.visible',
        document.body.clientWidth > 992,
    );
    const unfoldable = useLocalStorage('sidebar.unfoldable', false);

    const toggleVisible = (value) => {
        visible.value = value !== undefined ? value : !visible.value;
    };

    const toggleUnfoldable = () => {
        unfoldable.value = !unfoldable.value;
    };

    const close = () => {
        visible.value = false;
    };

    if (document.body.clientWidth <= 992) {
        close();
    }

    return { visible, unfoldable, toggleVisible, toggleUnfoldable, close };
});
