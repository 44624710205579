<template>
    <span v-if="number" class="d-flex align-items-center" style="gap: 2px">
        <icon class="text-primary" icon="cil-phone" />
        <a :href="link" target="_blank">{{ number }}</a>
    </span>
</template>
<script>
export default {
    name: 'PhoneLink',
    props: {
        number: String,
    },
    computed: {
        link() {
            return 'tel:' + this.number;
        },
    },
};
</script>
