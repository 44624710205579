<template>
    <div class="d-flex align-items-start" style="gap: 10px">
        <component
            :is="'jet-' + element.type"
            v-for="element in config"
            :key="element.name"
            v-model="payload[element.name]"
            :horizontal="false"
            :inline="true"
            :options="element.options.data"
            :placeholder="element.label"
            class="mb-0"
            @update:model-value="updatePayload"
        />
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';

export default {
    name: 'CustomForm',
    components: { JetSelect },
    props: {
        config: {},
    },
    data() {
        return {
            payload: {},
        };
    },
    computed: {
        payloadProperties() {
            return this.config.map((el) => el.name);
        },
    },
    beforeCreated() {
        this.payload = {};
        this.payloadProperties.forEach((prop) => {
            this.payload[prop] = null;
        });
    },
    methods: {
        updatePayload() {
            this.$emit('update', this.payload);
        },
    },
};
</script>
