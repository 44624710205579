<template>
    <div
        class="d-inline-flex btn-toggle align-items-center border-primary rounded-pill position-relative flex-shrink-0"
        style="max-width: 100%; border: 1px solid"
        @click.stop="toggleValue()"
    >
        <div
            v-for="option in options"
            :key="option.value"
            :class="{ selected: option.value === modelValue }"
            class="btn-toggle-option text-truncate"
            style="z-index: 1"
        >
            {{ option.label }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'toggle-button',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        modelValue: {},
    },
    computed: {
        selected() {
            return this.options.find((option) => option.value === this.value);
        },
    },
    methods: {
        toggleValue() {
            if (this.modelValue === this.options[0].value) {
                this.$emit('update:modelValue', this.options[1].value);
            } else {
                this.$emit('update:modelValue', this.options[0].value);
            }
        },
    },
};
</script>
