if (!Array.prototype.first) {
    Array.prototype.first = function () {
        return this[0];
    };
}

if (!Array.prototype.last) {
    Array.prototype.last = function () {
        return this[this.length - 1];
    };
}

if (!Array.prototype.filtered) {
    Array.prototype.filtered = function () {
        return this.filter((element) => {
            if (element.hasOwnProperty('condition')) {
                return element.condition;
            }
            return !!element;
        });
    };
}

if (!Array.prototype.unique) {
    Array.prototype.unique = function () {
        return Array.from(new Set(this));
    };
}

export const pipe = (value) => ({
    value,
    to: (cb) => pipe(cb(value)),
});

export const removeEmptyProperties = function (obj) {
    const ret = {};

    if (!obj) {
        return ret;
    }

    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        if (
            (Array.isArray(value) && value.length === 0) ||
            [undefined, null, ''].includes(value)
        ) {
            return;
        }

        ret[key] = value;
    });

    return ret;
};
