<template>
    <app-layout>
        <h2>Roles/Permissions Matrix</h2>
        <div v-for="group in groupedPermissions">
            <h4 class="mb-0">{{ groupName(group) }}</h4>
            <permissions-table :permissions="group.permissions" class="mb-4" />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import PermissionsTable from '@/Pages/Users/Permissions/PermissionsTable.vue';

export default {
    components: { PermissionsTable, AppLayout },
    computed: {
        groupedPermissions() {
            // Group permissions by group attribute
            let groupedPermissions = [];
            this.$page.props.permissions.forEach((permission) => {
                let group = groupedPermissions.find(
                    (el) => el.group === permission.group
                );

                if (!group) {
                    group = { group: permission.group, permissions: [] };
                    groupedPermissions.push(group);
                }

                group.permissions.push(permission);
            });
            return groupedPermissions;
        },
    },
    methods: {
        groupName(group) {
            return group.group.replace('_', ' ').toUpperCase();
        },
    },
};
</script>
