<template>
    <li
        class="nav-item subnav-item d-flex justify-content-center"
    >
        <Link
            :class="{ 'c-active': active }"
            :href="href"
            class="nav-link"
        >
            <span><slot/></span
            ></Link>
    </li>
</template>
<script>
export default {
    props: ['href', 'active'],
};
</script>
