<template>
    <div>
        <div v-html="description" />
        <div>
            <small
                >Data: <span class="text-muted">{{ type }}</span></small
            >
        </div>
        <div>
            <small
                >Value:
                <span class="text-muted">{{
                    item.properties.value
                }}</span></small
            >
        </div>
    </div>
</template>
<script>
export default {
    name: 'PerformanceDataEntry',
    props: {
        item: {},
    },
    computed: {
        type() {
            switch (this.item.properties.type) {
                case 'alarms':
                    return 'Alarms';
                case 'throughput':
                    return 'Throughput';
            }
        },
        description() {
            switch (this.item.event) {
                case 'performance-data.created':
                    return 'Captured performance data.';
                case 'performance-data.updated':
                    return 'Updated performance data record.';
                case 'performance-data.deleted':
                    return 'Deleted performance data record.';
            }
        },
    },
};
</script>
