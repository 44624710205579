<template>
    <app-layout>
        <div>
            <page-header :title="asset.name" />
            <row>
                <column>
                    <jet-form-section @submitted="handleSubmit">
                        <template #title
                            >{{ __('stakeholders.edit.header.title') }}
                        </template>

                        <template #form>
                            <h2>{{ __('stakeholders.overview.title') }}</h2>

                            <multi-select-dropdown
                                v-if="hasAnyPermission('asset.update')"
                                v-model="form.owners"
                                :invalid-feedback="form.errors.owners"
                                :is-form-field="true"
                                :label="
                                    __('stakeholders.edit.field.owners.label')
                                "
                                :options="owners"
                                :placeholder="
                                    __(
                                        'stakeholders.edit.field.owners.placeholder',
                                    )
                                "
                                :show-searchbar="true"
                                :show-select-all-button="false"
                                footer-classes="bg-gray-light"
                            >
                                <template #footer="{ hideElement }">
                                    <button-add-new
                                        v-if="hasAnyPermission('owner.create')"
                                        @click.native="addNewOwner(hideElement)"
                                    />
                                </template>
                            </multi-select-dropdown>
                            <multi-select-dropdown
                                v-if="hasAnyPermission('asset.update')"
                                v-model="form.operators"
                                :invalid-feedback="form.errors.operators"
                                :is-form-field="true"
                                :label="
                                    __(
                                        'stakeholders.edit.field.operators.label',
                                    )
                                "
                                :options="operators"
                                :placeholder="
                                    __(
                                        'stakeholders.edit.field.operators.placeholder',
                                    )
                                "
                                :show-searchbar="true"
                                :show-select-all-button="false"
                                footer-classes="bg-gray-light"
                            >
                                <template #footer="{ hideElement }">
                                    <button-add-new
                                        v-if="
                                            hasAnyPermission('operator.create')
                                        "
                                        @click.native="
                                            addNewOperator(hideElement)
                                        "
                                    />
                                </template>
                            </multi-select-dropdown>
                            <multi-select-dropdown
                                v-if="hasAnyPermission('asset.update')"
                                v-model="form.maintainers"
                                :invalid-feedback="form.errors.maintainers"
                                :is-form-field="true"
                                :label="
                                    __(
                                        'stakeholders.edit.field.maintainers.label',
                                    )
                                "
                                :options="maintainers"
                                :placeholder="
                                    __(
                                        'stakeholders.edit.field.maintainers.placeholder',
                                    )
                                "
                                :show-searchbar="true"
                                :show-select-all-button="false"
                                footer-classes="bg-gray-light"
                            >
                                <template #footer="{ hideElement }">
                                    <button-add-new
                                        v-if="
                                            hasAnyPermission(
                                                'maintainer.create',
                                            )
                                        "
                                        @click.native="
                                            addNewMaintainer(hideElement)
                                        "
                                    />
                                </template>
                            </multi-select-dropdown>
                        </template>
                        <template #actions>
                            <JetFormButtons
                                :cancelRoute="$route('assets.show', asset)"
                                :disabled="!form.isDirty || form.processing"
                                :is-processing="form.processing"
                                :is-update-form="true"
                            />
                        </template>
                    </jet-form-section>
                </column>
            </row>
            <update-or-create-owner-form-modal
                v-if="hasAnyPermission('owner.create')"
                @created="refreshData('owners')"
            />
            <update-or-create-operator-form-modal
                v-if="hasAnyPermission('operator.create')"
                @created="refreshData('operators')"
            />
            <update-or-create-maintainer-form-modal
                v-if="hasAnyPermission('maintainer.create')"
                @created="refreshData('maintainers')"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import { eventBus, events } from '@/eventBus.js';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import UpdateOrCreateMaintainerFormModal from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerFormModal.vue';
import UpdateOrCreateOperatorFormModal from '@/Pages/Stakeholders/Operators/UpdateOrCreateOperatorFormModal.vue';
import UpdateOrCreateOwnerFormModal from '@/Pages/Stakeholders/Owners/UpdateOrCreateOwnerFormModal.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    name: 'StakeholdersEdit',
    components: {
        PageHeader,
        ButtonAddNew,
        UpdateOrCreateOwnerFormModal,
        UpdateOrCreateOperatorFormModal,
        JetFormButtons,
        MultiSelectDropdown,
        JetFormSection,
        AppLayout,
        UpdateOrCreateMaintainerFormModal,
    },
    data() {
        return {
            form: this.$inertia.form({
                owners: [],
                operators: [],
                maintainers: [],
            }),
        };
    },
    computed: {
        owners() {
            return this.$page.props.owners.data.map((owner) => ({
                label: owner.name,
                value: owner.id,
            }));
        },
        operators() {
            return this.$page.props.operators.data.map((operator) => ({
                label: operator.name,
                value: operator.id,
            }));
        },
        maintainers() {
            return this.$page.props.maintainers.data.map((maintainer) => ({
                label: maintainer.name,
                value: maintainer.id,
            }));
        },
        asset() {
            return this.$page.props.asset;
        },
    },
    mounted() {
        this.form = this.$inertia.form({
            owners: this.asset.owners?.map((owner) => owner.id) || [],
            operators:
                this.asset.operators?.map((operator) => operator.id) || [],
            maintainers:
                this.asset.maintainers?.map((maintainer) => maintainer.id) ||
                [],
        });
    },
    methods: {
        handleSubmit() {
            this.form.put(route('assets.stakeholders.update', this.asset));
        },
        addNewMaintainer(hideElement) {
            hideElement();
            eventBus.$emit(events.openCreateMaintainerModal);
        },
        addNewOperator(hideElement) {
            hideElement();
            eventBus.$emit(events.openCreateOperatorModal);
        },
        addNewOwner(hideElement) {
            hideElement();
            eventBus.$emit(events.openCreateOwnerModal);
        },
        refreshData(type) {
            this.$inertia.reload({ only: [type] });
        },
    },
};
</script>
