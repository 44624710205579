<template>
    <collapsable-filter-bar
        :active="activeFilters.isDirty"
        :is-processing="isProcessing"
        @clear="clearFilters"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                v-model="activeFilters.search"
                :is-processing="isProcessing"
                :placeholder="__('assets.overview.filter.placeholder')"
                :show-spinner="!isOpen"
            />
        </template>

        <location-filter :form="activeFilters" />

        <date-range-filter
            v-model="activeFilters.date"
            :label="__('activities.overview.cell.date')"
        />
        <multi-select-dropdown
            v-if="types.length > 1"
            v-model="activeFilters.types"
            :label="__('assets.overview.filter.type')"
            :options="types"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="gap: 1rem"
                >
                    <span>{{ option.label }}</span
                    ><small class="text-muted">{{ option.value }}</small>
                </div>
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-model="activeFilters.results"
            :label="__('test_records.overview.cell.result')"
            :options="results"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ option.label }}</span>
                    <icon
                        v-if="option.value === 'success'"
                        class="text-gray"
                        name="cil-check-alt"
                        size="lg"
                    />
                    <icon
                        v-else
                        class="text-danger"
                        name="cil-warning"
                        size="lg"
                    />
                </div>
            </template>
        </multi-select-dropdown>
        <user-filter v-model="activeFilters.users" />
        <multi-select-dropdown
            v-model="activeFilters.qr"
            :label="__('test_records.overview.cell.qr_code_scanned')"
            :options="[
                {
                    label: __('test_records.overview.tooltip.qr_code_scanned'),
                    value: 'true',
                },
                {
                    label: __(
                        'test_records.overview.tooltip.qr_code_not_scanned',
                    ),
                    value: 'false',
                },
            ]"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option, show }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ option.label }}</span>
                    <icon
                        v-if="option.value === 'true'"
                        class="text-gray"
                        name="cil-check-alt"
                        size="lg"
                    />
                    <icon v-else name="cil-x" size="lg" />
                </div>
            </template>
        </multi-select-dropdown>
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import TruncatedLabel from '@/Pages/WorkOrders/TruncatedLabel.vue';
import Icon from '@/Components/Icon.vue';
import DateRangeFilter from '@/Components/DateRangeFilter.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import UserFilter from '@/Components/UserFilter.vue';

export default {
    components: {
        UserFilter,
        SearchFilter,
        Icon,
        TruncatedLabel,
        DateRangeFilter,
        LocationFilter,
        CollapsableFilterBar,
        MultiSelectDropdown,
    },
    props: {
        isProcessing: Boolean,
    },
    emits: ['change'],
    data() {
        return {
            activeFilters: this.$inertia.form({
                locations: [],
                specificLocations: [],
                date: { start: null, end: null },
                types: [],
                results: [],
                users: [],
                qr: [],
                search: null,
            }),
        };
    },
    computed: {
        results() {
            return this.$page.props.results?.data || [];
        },
        types() {
            return this.$page.props.types?.data || [];
        },
    },
    created() {
        this.readValuesFromUrl();
        window.addEventListener('keyup', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keyup', this.keyboardListener);
    },

    mounted() {
        this.readValuesFromUrl();

        this.$watch(
            (vm) => [
                vm.activeFilters.locations,
                vm.activeFilters.specificLocations,
                vm.activeFilters.date.start,
                vm.activeFilters.date.end,
                vm.activeFilters.types,
                vm.activeFilters.results,
                vm.activeFilters.users,
                vm.activeFilters.qr,
                vm.activeFilters.search,
            ],
            () => {
                this.$emit('change', this.activeFilters.data());
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },
    methods: {
        resetFilters() {
            this.activeFilters.reset();
        },
        readValuesFromUrl() {
            const {
                start,
                end,
                types,
                results,
                users,
                qr,
                search,
                locations,
                specificLocations,
            } = this.route().params;

            this.activeFilters.locations = locations || [];
            this.activeFilters.specificLocations = specificLocations || [];
            this.activeFilters.date.start = start || null;
            this.activeFilters.date.end = end || null;
            this.activeFilters.types = types || [];
            this.activeFilters.qr = qr || [];
            this.activeFilters.results = results || [];
            this.activeFilters.users = users || [];
            this.activeFilters.search = search || null;
        },
        clearFilters() {
            this.resetFilters();
        },
    },
};
</script>
