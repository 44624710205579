<template>
    <app-layout>
        <page-header :title="__('profile.information.title')" />
        <div class="row">
            <div class="col-md-6">
                <div v-if="$page.props.jetstream.canUpdateProfileInformation">
                    <update-profile-information-form />
                </div>

                <div v-if="$page.props.jetstream.canUpdatePassword">
                    <update-password-form class="mt-10 sm:mt-0" />
                </div>

                <div
                    v-if="
                        $page.props.jetstream.canManageTwoFactorAuthentication
                    "
                >
                    <two-factor-authentication-form class="mt-10 sm:mt-0" />
                </div>

                <update-user-settings-form />

                <template
                    v-if="$page.props.jetstream.hasAccountDeletionFeatures"
                >
                    <delete-user-form class="mt-10 sm:mt-0" />
                </template>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetSectionBorder from '@/Jetstream/SectionBorder.vue';
import LogoutOtherBrowserSessionsForm from '@/Pages/Profile/LogoutOtherBrowserSessionsForm.vue';
import TwoFactorAuthenticationForm from '@/Pages/Profile/TwoFactorAuthenticationForm.vue';
import UpdatePasswordForm from '@/Pages/Profile/UpdatePasswordForm.vue';
import UpdateProfileInformationForm from '@/Pages/Profile/UpdateProfileInformationForm.vue';
import UpdateUserSettingsForm from '@/Pages/Profile/UpdateUserSettingsForm.vue';
import DeleteUserForm from '@/Pages/Profile/DeleteUserForm.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    props: ['sessions'],

    components: {
        PageHeader,
        DeleteUserForm,
        AppLayout,
        JetSectionBorder,
        LogoutOtherBrowserSessionsForm,
        TwoFactorAuthenticationForm,
        UpdatePasswordForm,
        UpdateProfileInformationForm,
        UpdateUserSettingsForm,
    },
};
</script>
