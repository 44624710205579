<template>
    <span ref="text">{{ text }}</span>
</template>
<script>
export default {
    name: 'TruncatedLabel',
    props: {
        text: { type: String, default: '' },
        show: { type: Boolean, default: false },
    },
    watch: {
        show(value) {
            if (!!value) {
                let node = this.$refs.text;
                let parentNode = node.parentElement;
                if (parentNode.scrollWidth > parentNode.offsetWidth) {
                    parentNode.title = this.text;
                }
            }
        },
    },
};
</script>
