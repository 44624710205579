<script>
import { h } from 'vue';

export default {
    functional: true,
    name: 'nl2br',
    props: {
        tag: {
            type: String,
            required: false,
            default: 'div',
        },
        text: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            required: false,
        },
    },
    render() {
        return h(
            this.$props.tag,
            {
                class: this.$props.className,
            },
            this.$props.text
                .split(/\\n|\n/g)
                .filter((el) => !!el.trim())
                .reduce((accumulator, string) => {
                    if (!Array.isArray(accumulator)) {
                        return [accumulator, h('br'), string];
                    }
                    return accumulator.concat([h('br'), string]);
                }),
        );
    },
};
</script>
