<template>
    <div v-if="shouldRender && hasAnyPermission(`${type}.delete`)">
        <jet-confirmation-modal
            :closeable="false"
            :show="showConfirmation"
            type="danger"
            @close="close"
        >
            <template #title>
                <slot name="title">
                    {{ __(`${type}s.modal.delete.title`) }}
                </slot>
            </template>

            <template #content>
                <slot name="content">
                    <div
                        v-text="__(`${type}s.modal.delete.content.question`)"
                    />
                    <div
                        v-if="hasLinkedItems"
                        v-text="
                            __(`${type}s.modal.delete.content.linked_items`)
                        "
                    />
                    <div v-if="stakeholder" class="text-center my-2">
                        <b>"{{ stakeholder.name }}"</b>
                    </div>
                    <jet-textarea
                        v-model="form.reason"
                        :horizontal="false"
                        :invalid-feedback="form.errors.reason"
                        :label="__(`${type}s.modal.delete.reason.label`)"
                        :placeholder="
                            __(`${type}s.modal.delete.reason.placeholder`)
                        "
                        :required="true"
                        :rows="3"
                    />
                </slot>
            </template>

            <template #footer>
                <slot name="footer">
                    <jet-button
                        :disabled="!form.isDirty"
                        :isProcessing="form.processing"
                        color="danger"
                        @click.stop="deleteStakeholder"
                    >
                        {{ __('general.action.delete') }}
                    </jet-button>
                </slot>
            </template>
        </jet-confirmation-modal>
        <dialog-modal :show="showDeletionProcessSelection" type="danger">
            <template #title>
                <slot name="title">
                    {{ __(`${type}s.modal.delete.title`) }}
                </slot>
            </template>
            <slot name="content">
                <div
                    v-html="
                        __(`${type}s.modal.delete.selection.content`, {
                            stakeholder: stakeholder?.name,
                        })
                    "
                />
                <jet-radio-group
                    v-model="selectedDeletionProcess"
                    :label="__(`${type}s.modal.delete.selection.label`)"
                    :options="deletionProcessOptions"
                    class="mt-4 mb-0"
                />
            </slot>
            <template #footer>
                <slot name="footer">
                    <jet-button color="secondary" @click.stop="close">
                        {{ __('general.action.cancel') }}
                    </jet-button>
                    <jet-button
                        color="primary"
                        @click.stop="continueToStakeholderUpdate"
                    >
                        {{ __('general.action.continue') }}
                    </jet-button>
                </slot>
            </template>
        </dialog-modal>
        <dialog-modal
            :show="showUpdateStakeholderModal"
            add-content-classes="modal-body-overflow-auto"
            type="danger"
        >
            <template #title>
                <slot name="title">
                    {{ __(`${type}s.modal.delete.title`) }}
                </slot>
            </template>
            <slot name="content">
                <div>
                    {{ __(`${type}s.modal.update.content`) }}
                </div>
                <div class="mt-3">
                    {{ __(`${type}s.modal.update.current`) }}
                </div>
                <div
                    v-if="stakeholder"
                    class="text-muted mb-3"
                    style="margin: 0 21px"
                >
                    {{ stakeholder.name }}
                </div>
                <jet-select
                    v-model="form.stakeholder"
                    :enable-search="true"
                    :horizontal="false"
                    :invalid-feedback="form.errors[`new_${type}`]"
                    :label="__(`${type}s.modal.update.stakeholder.label`)"
                    :options="stakeholders"
                    :placeholder="
                        __(`${type}s.modal.update.stakeholder.placeholder`)
                    "
                    :required="true"
                />
                <jet-textarea
                    v-model="form.reason"
                    :horizontal="false"
                    :invalid-feedback="form.errors.reason"
                    :label="__(`${type}s.modal.delete.reason.label`)"
                    :placeholder="
                        __(`${type}s.modal.delete.reason.placeholder`)
                    "
                    :required="true"
                    :rows="3"
                />
                <div>
                    <small
                        ><em class="text-danger">*</em>
                        {{ __('general.required_fields') }}</small
                    >
                </div>
            </slot>
            <template #footer>
                <slot name="footer">
                    <jet-button color="secondary" @click.stop="close">
                        {{ __('general.action.cancel') }}
                    </jet-button>
                    <jet-button
                        :disabled="!form.isDirty"
                        :is-processing="form.processing"
                        color="danger"
                        @click.stop="deleteStakeholder"
                    >
                        {{ __('general.action.confirm') }}
                    </jet-button>
                </slot>
            </template>
        </dialog-modal>
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetTextarea from '@/Jetstream/Textarea.vue';
import { eventBus, events } from '@/eventBus.js';
import JetRadioGroup from '@/Jetstream/RadioGroup.vue';
import JetSelect from '@/Jetstream/Select.vue';
import { $http } from '@/bootstrap.js';
import DialogModal from '@/Jetstream/DialogModal.vue';

export default {
    name: 'DeleteStakeholderModal',
    components: {
        DialogModal,
        JetRadioGroup,
        JetTextarea,
        JetConfirmationModal,
        JetButton,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            showConfirmation: false,
            showDeletionProcessSelection: false,
            showUpdateStakeholderModal: false,
            stakeholder: null,
            type: null,
            selectedDeletionProcess: 'update',
            stakeholders: [],
            form: this.$inertia.form({
                reason: null,
                stakeholder: null,
            }),
        };
    },
    computed: {
        deletionProcessOptions() {
            return [
                {
                    label: this.__(`${this.type}s.modal.delete.option.update`),
                    value: 'update',
                },
                {
                    label: this.__(`${this.type}s.modal.delete.option.delete`),
                    value: 'delete',
                },
            ];
        },
        hasLinkedItems() {
            return (
                this.stakeholder.assets_count > 0 ||
                this.stakeholder.workorders_count > 0
            );
        },
    },

    methods: {
        deleteStakeholder() {
            this.form
                .transform((data) => {
                    const type = `new_${this.type}`;

                    // Ensure that the new stakeholder property is not set
                    delete data[type];

                    // Set the property if needed
                    if (
                        this.hasLinkedItems &&
                        this.selectedDeletionProcess === 'update'
                    ) {
                        data[type] = data.stakeholder;
                    }

                    delete data.stakeholder;
                    return data;
                })
                .delete(
                    this.route(`${this.type}s.destroy`, {
                        id: this.stakeholder.id,
                    }),
                    {
                        onSuccess: () => {
                            this.close();
                            this.$emit('deleted', this.stakeholder);
                        },
                    },
                );
        },
        close() {
            this.showConfirmation = false;
            this.showDeletionProcessSelection = false;
            this.showUpdateStakeholderModal = false;
            this.selectedDeletionProcess = 'update';

            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
        reset() {
            this.form = this.$inertia.form({
                reason: null,
                stakeholder: null,
            });
        },
        continueToStakeholderUpdate() {
            this.loadStakeholders();
            this.showDeletionProcessSelection = false;
            this.showUpdateStakeholderModal =
                this.selectedDeletionProcess === 'update';
            this.showConfirmation = this.selectedDeletionProcess === 'delete';
        },
        loadStakeholders() {
            $http.get(this.route(`${this.type}s.index`)).then((response) => {
                this.stakeholders = response.data.data
                    .filter(
                        (stakeholder) => stakeholder.id !== this.stakeholder.id,
                    )
                    .map((stakeholder) => ({
                        label: stakeholder.name,
                        value: stakeholder.id,
                    }));
            });
        },
    },
    mounted() {
        eventBus.$on([events.openConfirmDeleteStakeholderModal], (data) => {
            this.stakeholder = data?.stakeholder || null;
            this.type = data.type;

            this.shouldRender = true;
            this.reset();

            this.$nextTick(() => {
                this.showConfirmation = !this.hasLinkedItems;
                this.showDeletionProcessSelection = this.hasLinkedItems;
            });
        });
    },
    beforeUnmount() {
        eventBus.$off([events.openConfirmDeleteStakeholderModal]);
    },
};
</script>
<style scoped>
:deep(.dropdown-menu) {
    max-height: 11rem;
}
</style>
