<template>
    <div class="d-flex justify-content-end align-items-center">
        <small class="text-muted">{{ text }} </small>
    </div>
</template>
<script>
export default {
    props: {
        text: String,
    },
};
</script>
