<template>
    <div>
        <data-table
            :columns="fields"
            :default-sorting="activeQueryParams.sort"
            :items="inoperableAssets"
            :no-items-view="noItemsView"
            class="mt-3"
            @update:sorter-value="updateSorting($event)"
            @row-clicked="showAsset"
        >
            <template #specificLocation-header>
                <div>Specific&nbsp;Location</div>
            </template>

            <template #location="{ item }">
                <td>
                    {{ `${item.location.name} (${item.location.iataCode})` }}
                </td>
            </template>

            <template #specificLocation="{ item }">
                <specific-location-overview-cell :location="item.location" />
            </template>

            <template #area="{ item }">
                <area-location-overview-cell :location="item.location" />
            </template>

            <template #type="{ item }">
                <td>{{ item.type }}</td>
            </template>

            <template #designation="{ item }">
                <td class="align-top">
                    <div>{{ item.designation }}</div>
                    <div class="text-muted">
                        <small class="text-nowrap text-dark"
                            >{{ __('assets.overview.cell.serial_number') }}:
                            {{ item.serialNumber }}</small
                        >
                    </div>
                </td>
            </template>

            <template #manufacturer="{ item }">
                <td>{{ item.manufacturer }}</td>
            </template>

            <template #status="{ item }">
                <daily-report-status-cell :item="item" />
            </template>
        </data-table>
        <pagination
            :paginator="$page.props.report.inoperableAssets"
            :query="activeQueryParams"
        />
    </div>
</template>
<script>
import Pagination from '@/Components/Pagination.vue';
import DailyReportStatusCell from '@/Pages/ReportingLight/DailyReportStatusCell.vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import DataTable from '@/Components/DataTable.vue';
import SpecificLocationOverviewCell from '@/Components/SpecificLocationOverviewCell.vue';
import AreaLocationOverviewCell from '@/Components/AreaLocationOverviewCell.vue';
import { when } from '@/Utils/when';

export default {
    name: 'daily-light-report-table',
    components: {
        AreaLocationOverviewCell,
        SpecificLocationOverviewCell,
        DataTable,
        SortIcon,
        DailyReportStatusCell,
        Pagination,
    },
    data() {
        return {
            activeQueryParams: { sort: { column: 'status', asc: false } },
            visibleWorkOrderDetails: [],
            noItemsView: {
                noItems: 'No Reports available',
            },
        };
    },
    computed: {
        fields() {
            return [
                when(this.locations?.length > 1, {
                    key: 'location',
                    label: 'Location',
                }),
                {
                    key: 'specificLocation',
                    label: 'Specific Location',
                },
                {
                    key: 'area',
                    label: 'Area',
                },
                {
                    key: 'type',
                    label: 'Type',
                },
                {
                    key: 'designation',
                    label: 'Designation',
                },
                {
                    key: 'manufacturer',
                    label: 'Manufacturer',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
            ].filter(Boolean);
        },
        inoperableAssets() {
            const notAvailable = this.__('general.not_available');
            return this.$page.props.report.inoperableAssets.data.map(
                (report) => ({
                    id: report.id,
                    location: report.location,
                    specificLocation: report.location.specificLocationName,
                    type: report.type,
                    designation: report.designation || notAvailable,
                    serialNumber: report.serialNumber || notAvailable,
                    manufacturer: report.manufacturer?.name || notAvailable,
                    status: {
                        usageCategory: report.status.usageCategory?.value,
                        operability: report.status.operability?.value,
                        inoperableSince: report.status.inoperableSince,
                        operabilityChangeReason:
                            report.status.operabilityChangeReason?.label,
                        additionalInformation:
                            report.status.additionalInformation,
                        downtimeInSeconds: report.status.downtimeInSeconds,
                        operabilityLastCheckedAt:
                            report.status.operabilityLastCheckedAt,
                        workOrders: report.status.workOrders,
                    },
                }),
            );
        },
        locations() {
            return this.$page.props.locations?.data || [];
        },
    },
    mounted() {
        this.readSortingFromUrl();
    },
    methods: {
        workOrderDetailsVisible(workOrder) {
            return this.visibleWorkOrderDetails.includes(workOrder.id);
        },
        toggleWorkOrderDetails(workOrder) {
            let idx = this.visibleWorkOrderDetails.indexOf(workOrder.id);
            if (idx > -1) {
                this.visibleWorkOrderDetails.splice(idx, 1);
            } else {
                this.visibleWorkOrderDetails.push(workOrder.id);
            }
        },
        updateSorting(event) {
            this.activeQueryParams.sort = event ?? {
                column: 'status',
                asc: false,
            };
            this.$emit('sort', this.activeQueryParams.sort);
        },
        readSortingFromUrl() {
            let sort = this.route().params?.sort?.split('.');
            if (sort && sort.length === 2) {
                this.activeQueryParams.sort = {
                    column: sort[0],
                    asc: sort[1] === 'asc',
                };
            } else {
                this.activeQueryParams.sort = { column: 'status', asc: false };
            }
        },
        showAsset(item) {
            this.$inertia.visit(this.route('assets.show', item.id));
        },
    },
};
</script>
