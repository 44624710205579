<template>
    <component
        :is="inline ? 'v-fragment' : 'row'"
        :class="[groupClasses]"
        role="group"
    >
        <form-label v-if="label" :class="labelClasses">{{ label }}</form-label>
        <column :class="inputClasses">
            <slot />
        </column>
    </component>
</template>
<script setup>
import FormLabel from '@/Components/FormLabel.vue';
import { computed } from 'vue';

const props = defineProps({
    label: {
        type: String,
        default: '',
    },
    labelClasses: {
        type: [String, Object, Array],
    },
    horizontal: {
        type: [Boolean, Object],
        default: true,
    },
    inline: {
        type: Boolean,
        default: false,
    },
    groupClasses: {
        type: [String, Object, Array],
        default: 'mb-3',
    },
});

const labelClasses = computed(() => {
    if (!props.horizontal) {
        return ['col-md-12', props.labelClasses];
    }

    return [props.horizontal.label || 'col-md-3', props.labelClasses];
});

const inputClasses = computed(() => {
    if (!props.horizontal) {
        return ['col-md-12'];
    }

    return props.horizontal.input || 'col-md-9';
});
</script>
