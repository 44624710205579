<template>
    <div class="d-flex align-items-center" style="min-width: 0">
        <component :is="workOrderTypeIcon" class="flex-shrink-0"/>
        <div
            v-if="text"
            :class="{ 'px-2': workOrderTypeIcon }"
            class="text-truncate"
        >
            {{ text }}
        </div>
    </div>
</template>
<script>
import CorrectiveMaintenance from '../../../../icons/CorrectiveMaintenance.vue';
import PreventativeMaintenance from '../../../../icons/PreventativeMaintenance.vue';

export default {
    props: {
        workOrder: {},
        text: {},
    },
    components: {
        CorrectiveMaintenance,
        PreventativeMaintenance,
    },
    computed: {
        workOrderTypeIcon() {
            let value = this.workOrder?.type?.value;

            if (!value) {
                return;
            }

            return (
                value.charAt(0).toUpperCase() +
                value.slice(1).replace(/_([a-z])/g, (g) => g[1].toUpperCase())
            );
        },
    },
};
</script>
