<template>
    <dropdown-with-action
        :horizontal="false"
        :invalidFeedback="invalidFeedback"
        :label="__('assets.details.manufacturer.label')"
        :modelValue="modelValue"
        :options="[]"
        :placeholder="__('assets.details.manufacturer.placeholder')"
        :required="true"
        :routes="{
            store: route('manufacturers.store'),
            index: route('manufacturers.index'),
        }"
        :showAction="hasAnyPermission('manufacturer.create')"
        @update:model-value="$emit('update:model-value', $event)"
    />
</template>
<script>
import DropdownWithAction from '@/Components/DropdownWithAction.vue';

export default {
    name: 'manufacturer-dropdown',
    components: { DropdownWithAction },
    props: {
        invalidFeedback: String,
        modelValue: String,
    },
};
</script>
