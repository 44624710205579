<template>
    <div
        class="work-order-badge position-relative d-flex justify-content-center align-items-center"
    >
        <wrench />
        <small class="text-primary position-absolute counter">{{
            count || 0
        }}</small>
    </div>
</template>
<script>
import Wrench from '../../icons/Wrench.vue';

export default {
    name: 'work-order-badge',
    props: ['count'],
    components: { Wrench },
};
</script>
<style scoped>
.counter {
    font-size: 0.625rem;
    top: 9px;
    left: 12px;
    font-weight: bold;
    max-width: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
