<template>
    <div class="overflow-hidden">
        <a
            v-if="additionalInformation"
            v-c-popover="{
                placement: 'bottom',
                content:
                    `<b>${__(
                        'assets.modal.operability_change.additional_information.label',
                    )}</b><br/>` + additionalInformation,
            }"
            class="d-inline-block popover-no-header clickable"
            @click.stop=""
            >{{ reason }}
        </a>
        <div v-else>
            {{ reason }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'AdditionalStatusInformation',
    props: {
        reason: {},
        additionalInformation: {},
    },
};
</script>
