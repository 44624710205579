<template>
    <jet-form-section id="settings" @submitted="updateProfileInformation">
        <template #title>
            {{ __('profile.settings.header') }}
        </template>

        <template #form>
            <!-- Name -->
            <div class="col-span-6 sm:col-span-4">
                <jet-checkbox
                    v-model="form.receives_email_notifications"
                    :description="
                        __(
                            'profile.settings.description.receives_email_notifications',
                        )
                    "
                    :invalidFeedback="form.errors.receives_email_notifications"
                    :label="
                        __(
                            'profile.settings.label.receives_email_notifications',
                        )
                    "
                />
            </div>
            <div class="col-span-6 sm:col-span-4 mt-4">
                <jet-select
                    v-model="form.language"
                    :invalidFeedback="form.errors.language"
                    :label="__('users.edit_user.field.language.label')"
                    :options="supportedLanguages"
                    :resettable="false"
                />
            </div>
        </template>

        <template #actions>
            <jet-action-message :on="form.recentlySuccessful">
                {{ __('general.message.saved') }}
            </jet-action-message>

            <jet-button
                :disabled="!form.isDirty"
                :isProcessing="form.processing"
                color="primary"
                >{{ __('general.action.save') }}
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import { getLabelForLocale } from '@/Utils/getLabelForLocale.ts';

export default {
    components: {
        JetActionMessage,
        JetButton,
        JetFormSection,
        JetCheckbox,
        JetInputError,
        JetLabel,
        JetSelect,
    },

    data() {
        return {
            form: this.$inertia.form({
                _method: 'PUT',
                receives_email_notifications: true,
                language: '',
            }),
        };
    },

    computed: {
        supportedLanguages() {
            return this.$page.props.supported_locales.map((key) => ({
                value: key,
                label: getLabelForLocale(key),
            }));
        },
    },

    mounted() {
        this.form = this.$inertia.form({
            _method: 'PUT',
            receives_email_notifications:
                this.user.settings.receives_email_notifications,
            language: this.user.settings.language || this.$page.props.locale,
        });
    },

    methods: {
        updateProfileInformation() {
            if (this.$refs.photo) {
                this.form.photo = this.$refs.photo.files[0];
            }

            this.form.put(route('user.settings.update'), {
                errorBag: 'updateUserSettings',
                preserveScroll: true,
            });
        },
    },
};
</script>
