<template>
    <app-layout>
        <asset-details-header v-if="asset" @workorder-created="forceRerender" />
        <div class="py-3">
            <work-order-board
                :key="`wb-${componentKey}`"
                :selected-work-order="selectedWorkOrder?.id"
                :swimlanes="swimlanes"
                class="pb-3"
            />

            <h3 class="px-2 py-2 h5">
                {{ __('assets.workorders.records.title') }}
                <info-icon
                    :tooltip="__('assets.workorders.records.info')"
                    style="position: relative; top: -1px"
                />
            </h3>

            <work-order-records
                :key="`wr-${componentKey}`"
                :work-orders="archivedWorkOrders"
            />

            <work-order-details-modal />
            <asset-status-change-prompt-modal />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import WorkOrderDetailsModal from '@/Pages/WorkOrders/Modals/WorkOrderDetailsModal.vue';
import ConfirmWorkOrderDeletionModal from '@/Pages/WorkOrders/Modals/ConfirmWorkOrderDeletionModal.vue';
import WorkOrderRecords from '@/Pages/WorkOrders/WorkOrderRecords.vue';
import WorkOrderBoard from '@/Pages/WorkOrders/WorkOrderBoard.vue';
import InfoIcon from '@/Components/InfoIcon.vue';
import AssetStatusChangePromptModal from '@/Pages/WorkOrders/Modals/AssetStatusChangePromptModal.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    components: {
        AssetStatusChangePromptModal,
        InfoIcon,
        WorkOrderBoard,
        WorkOrderRecords,
        ConfirmWorkOrderDeletionModal,
        WorkOrderDetailsModal,
        AssetDetailsHeader,
        AppLayout,
    },
    data() {
        return {
            componentKey: 0,
            selectedWorkOrder: null,
            assetStatusChange: {
                assetId: null,
                operability: 'inoperable',
                reason: 'corrective_maintenance_required',
            },
        };
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
        workOrders() {
            return this.$page.props.workOrders?.data || [];
        },
        archivedWorkOrders() {
            return this.$page.props.archivedWorkOrders;
        },
        swimlanes() {
            return this.$page.props.swimlanes;
        },
    },
    mounted() {
        this.loadWorkOrderDetails(this.$page.props.selectedWorkOrder);
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
        closeModal() {
            this.forceRerender();

            setTimeout(() => {
                this.selectedWorkOrder = null;
            }, 300);
        },
        loadWorkOrderDetails(workOrder) {
            eventBus.$emit(events.openWorkOrderDetailsModal, {
                workOrderId: workOrder?.id,
            });
        },
    },
};
</script>
