<template>
    <div class="position-relative">
        <slot />
        <div
            v-if="enabled"
            v-c-tooltip="{
                content: tooltip,
                popperOptions: {
                    positionFixed: true,
                },
            }"
            style="position: absolute; left: 0; top: 0; right: 0; bottom: 0"
        />
    </div>
</template>
<script>
export default {
    name: 'TooltipWrapper',
    props: {
        enabled: {},
        tooltip: {},
    },
};
</script>
