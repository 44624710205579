import { DateTime } from 'luxon';
import translations from '../../lang/en.json';

export default function (value, locale) {
    if (!value) return '';

    if (!locale) {
        throw "Parameter 'locale' is missing!";
    }

    const dateTime = DateTime.fromISO(value);
    const diffInSeconds = Math.abs(dateTime.diffNow('seconds').values.seconds);

    if (diffInSeconds > 0 && diffInSeconds <= 60) {
        return translations['general.just_now'] || 'Just now';
    }

    return dateTime.setLocale(locale).toRelative();
}
