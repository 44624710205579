<template>
    <div style="padding: 5px 0">
        <header class="d-flex justify-content-between align-items-center mb-2">
            <div>
                {{
                    __(
                        'dashboard.asset_management.asset_type_distribution.title'
                    )
                }}
            </div>
            <info-icon
                :tooltip="
                    __(
                        'dashboard.asset_management.asset_type_distribution.tooltip'
                    )
                "
            />
        </header>
        <div
            class="d-flex align-items-center overflow-auto pb-3"
            style="gap: 6px"
        >
            <div
                class="db-card-sm text-center py-2 px-2 clickable"
                v-for="type in assetsByType"
                tabindex="0"
                :key="type.type?.value"
                style="
                    min-width: 75px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    cursor: pointer;
                "
                @click.stop="$emit('selected', type.type?.value)"
            >
                <div style="font-size: 30px; white-space: nowrap">
                    {{ type.count }}
                </div>
                <div>
                    {{ type.type?.value || __('general.not_available') }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/Components/InfoIcon.vue';

export default {
    name: 'AssetTypeDistribution',
    components: { InfoIcon },
    props: {
        assetsByType: {},
    },
};
</script>
