<template>
    <div v-if="hasErrors">
        <div class="is-invalid"></div>
        <div class="invalid-feedback">
            <ul class="list-unstyled">
                <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JetValidationErrors',

    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },
    },
};
</script>
