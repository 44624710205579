<template>
    <div class="vh-100 d-flex justify-content-center align-items-center">
        <div>
            <h1>{{ title }}</h1>
            <div>{{ description }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
    },
    mounted() {
        if (!this.title && !this.description) {
            this.title = '404 | Not found!';
            this.description =
                'Sorry, the page you are looking for could not be found.';
        }
    },
};
</script>
<style scoped></style>
