<template>
    <app-layout>
        <row>
            <column md="4">
                <card>
                    <div class="d-flex justify-content-center">
                        <icon
                            class="profile-placeholder mt-3 mx-5 mb-4"
                            icon="cil-user"
                        />
                    </div>
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h4 class="mb-0">{{ user.name }}</h4>
                        <div>
                            <role-badge
                                v-for="role in user.roles"
                                :key="role.id"
                                :role="role"
                            />
                        </div>
                    </div>
                    <email-link :email="user.email" size="sm" />
                    <div class="mt-5">
                        <h5>{{ __('users.show.airports.header') }}</h5>
                        <ul v-if="locations" class="list-unstyled">
                            <li
                                v-for="location in locations"
                                :key="location.id"
                                class="d-flex justify-content-start align-items-start mb-2"
                            >
                                <div>
                                    <icon
                                        v-if="location.countryCodeAlpha2"
                                        v-c-tooltip="{
                                            content: location.countryCodeAlpha2,
                                        }"
                                        :name="
                                            'cif-' +
                                            location.countryCodeAlpha2.toLowerCase()
                                        "
                                        size="xl"
                                    />
                                </div>
                                <div class="mx-2">{{ location.name }}</div>
                                <div class="text-muted">
                                    ({{ location.icaoCode }})
                                </div>
                            </li>
                        </ul>
                        <div v-else>
                            {{ __('users.show.airports.empty') }}
                        </div>
                    </div>
                    <template #footer>
                        <div
                            v-if="
                                hasAnyPermission(['user.index', 'user.update'])
                            "
                            class="d-flex justify-content-center"
                            style="gap: 10px"
                        >
                            <jet-button
                                :href="$route('users.index')"
                                class="btn btn-link text-muted"
                                >{{ __('general.action.cancel') }}
                            </jet-button>
                            <jet-button
                                v-if="hasAnyPermission(['user.update'])"
                                :href="$route('users.edit', user.id)"
                                class="btn btn-primary"
                                >{{ __('general.action.edit') }}
                            </jet-button>
                        </div>
                    </template>
                </card>
            </column>
            <column md="8">
                <activity-log
                    v-if="hasAnyPermission(['user.activity.read'])"
                    :activities="activities"
                    :fields="fields"
                    context="user"
                />
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import RoleBadge from '@/Components/RoleBadge.vue';
import Pagination from '@/Components/Pagination.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';

import Card from '@/Components/Card.vue';
import EmailLink from '@/Components/EmailLink.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    components: {
        JetButton,
        EmailLink,
        Card,

        AppLayout,
        ActivityLog,
        Pagination,
        RoleBadge,
    },

    data() {
        return {
            changes: null,
            fields: [
                {
                    key: 'date',
                    label: this.__('activities.overview.cell.date'),
                    sorter: false,
                },
                {
                    key: 'event',
                    label: this.__('activities.overview.cell.event'),
                    sorter: false,
                },
            ],
        };
    },

    computed: {
        user() {
            return this.$page.props.user;
        },
        activities() {
            return this.$page.props.activities;
        },
        locations() {
            return this.user.locations;
        },
    },

    methods: {
        getMailLink(email) {
            return 'mailto:' + email;
        },
    },
};
</script>
<style scoped>
.profile-placeholder {
    display: block;
    width: 50% !important;
    height: auto !important;
}
</style>
