import {DateTime, IANAZone} from "luxon";
import dateTimeMedium from "../filters/dateTimeMedium";

export class Timezone {

    id: string;

    constructor(identifier: string) {
        this.id = identifier
    }

    getLocalTime(locale: string = 'en'): string {
        return dateTimeMedium(DateTime.local({zone: this.id}).toString(), locale, this.id);
    }

    get shortName(): string {
        return new IANAZone(this.id).offsetName(new Date(), {
            format: 'short',
        })
    }
}
