<template>
    <a v-if="href" :href="href">
        <slot />
    </a>
    <span v-else>
        <slot />
    </span>
</template>
<script>
export default {
    props: {
        href: String,
    },
};
</script>
