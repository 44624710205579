<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasAnyPermission(['maintainer.create', 'maintainer.update'])
        "
        :closeable="false"
        :is-processing="form.processing"
        :primary-button-label="
            !maintainer ? __('general.action.add') : __('general.action.save')
        "
        :show="show"
        :submit-disabled="!form.isDirty"
        @close="close"
        @submit="submitForm"
    >
        <template #title>{{ title }}</template>

        <form :key="componentKey" @submit.prevent="submitForm">
            <jet-input
                v-model="form.name"
                :autofocus="true"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                :invalidFeedback="form.errors.name"
                :label="__('maintainers.form.name.label')"
                :placeholder="__('maintainers.form.name.placeholder')"
                :required="true"
            />
            <row>
                <column sm="4"
                    >{{ __('maintainers.form.contact_details.label') }}
                </column>
                <column sm="8">
                    <jet-input
                        v-model="form.email"
                        :horizontal="false"
                        :invalidFeedback="form.errors.email"
                        :label="__('stakeholders.form.email.label')"
                        :placeholder="__('stakeholders.form.email.placeholder')"
                        :required="true"
                    />
                    <jet-input
                        v-model="form.phone"
                        :horizontal="false"
                        :invalidFeedback="form.errors.phone"
                        :label="__('stakeholders.form.phone.label')"
                        :placeholder="__('stakeholders.form.phone.placeholder')"
                    />
                    <jet-input
                        v-model="form.description"
                        :horizontal="false"
                        :invalidFeedback="form.errors.description"
                        :label="__('stakeholders.form.description.label')"
                        :placeholder="
                            __('stakeholders.form.description.placeholder')
                        "
                    />
                </column>
            </row>
            <row>
                <column sm="4">{{ __('maintainers.form.sla.label') }}</column>
                <column sm="8">
                    <service-level-agreement-form
                        v-model="form.sla"
                        :errors="form.errors"
                    />
                </column>
            </row>
        </form>

        <alert v-if="slaExpired || slaExpiresSoon" color="warning"
            ><span v-if="slaExpired">{{
                __('stakeholders.form.sla.expired')
            }}</span>
            <span v-else>{{ __('stakeholders.form.sla.expires_soon') }}</span>
        </alert>

        <required-fields />
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';
import ServiceLevelAgreementForm from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementForm.vue';
import { DateTime } from 'luxon';
import Alert from '@/Components/Alert.vue';
import RequiredFields from '@/Components/RequiredFields.vue';
import { $http } from '@/bootstrap.js';

const defaultSla = {
    timeToRespond: {
        high: {
            unit: 'hours',
            value: null,
        },
        medium: {
            unit: 'hours',
            value: null,
        },
        low: {
            unit: 'hours',
            value: null,
        },
    },
    timeToResolve: {
        high: {
            unit: 'hours',
            value: 12,
        },
        medium: {
            unit: 'hours',
            value: 12,
        },
        low: {
            unit: 'hours',
            value: 12,
        },
    },
    workingHours: '24/7',
    agreement: {
        startDate: null,
        endDate: null,
    },
};

export default {
    name: 'UpdateOrCreateMaintainerFormModal',
    emits: ['created', 'updated'],
    components: {
        RequiredFields,
        Alert,
        ServiceLevelAgreementForm,
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            maintainer: null,
            componentKey: 0,
            form: this.$inertia.form({
                name: '',
                email: '',
                phone: '',
                description: '',
                sla: Object.assign({}, defaultSla),
            }),
        };
    },
    mounted() {
        eventBus.$on(
            [events.openCreateMaintainerModal, events.openEditMaintainerModal],
            (data) => {
                this.maintainer = data?.maintainer || null;
                this.reset();

                this.showModal();

                if (this.maintainer) {
                    this.loadMaintainerInfo();
                }
            },
        );
    },
    beforeUnmount() {
        eventBus.$off([
            events.openCreateMaintainerModal,
            events.openEditMaintainerModal,
        ]);
    },
    computed: {
        title() {
            return !!this.maintainer
                ? this.__('maintainers.edit.title')
                : this.__('maintainers.create.title');
        },
        slaExpirationDate() {
            if (!this.form.sla.agreement.endDate) {
                return null;
            }
            return DateTime.fromFormat(
                this.form.sla.agreement.endDate,
                'y-MM-dd',
            ).startOf('day');
        },
        slaExpiresSoon() {
            return (
                (this.slaExpirationDate?.diffNow('months').months >= 0 &&
                    this.slaExpirationDate?.diffNow('months').months <= 3) ||
                false
            );
        },
        slaExpired() {
            return this.slaExpirationDate?.diffNow('days').days < -1 || false;
        },
    },
    methods: {
        loadMaintainerInfo() {
            $http
                .get(this.route('maintainers.edit', this.maintainer))
                .then((response) => {
                    this.maintainer = response.data.maintainer;
                    this.form = this.$inertia.form({
                        name: this.maintainer?.name || '',
                        email: this.maintainer?.email || '',
                        phone: this.maintainer?.phone || '',
                        description: this.maintainer?.description || '',
                        sla:
                            this.maintainer?.sla ||
                            Object.assign({}, defaultSla),
                    });

                    this.componentKey += 1;
                });
        },
        submitForm() {
            let route = this.maintainer
                ? this.route('maintainers.update', {
                      maintainer: this.maintainer,
                  })
                : this.route('maintainers.store');

            this.form
                .transform((data) => {
                    if (this.maintainer) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.close();

                        this.$emit(
                            this.maintainer ? 'updated' : 'created',
                            this.maintainer,
                        );
                    },
                });
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },

        showModal() {
            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        reset() {
            this.form = this.$inertia.form({
                name: '',
                email: '',
                phone: '',
                description: '',
                sla: Object.assign({}, defaultSla),
            });
        },
    },
};
</script>
