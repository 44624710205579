<template>
    <div>
        <div v-if="attachments && attachments.length && canRead">
            <c-list-group>
                <c-list-group-item
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    :class="{ 'border-0': inline }"
                    class="d-flex p-0"
                >
                    <div v-if="!inline" class="d-flex align-items-center">
                        <div
                            v-if="attachment.thumbnail"
                            :style="{
                                background:
                                    'url(' +
                                    attachment.thumbnail +
                                    ') center center no-repeat',
                            }"
                            class="media"
                        ></div>
                        <div
                            v-else
                            class="media d-flex align-items-center justify-content-center"
                        >
                            <icon icon="cil-file" />
                        </div>
                    </div>
                    <div
                        class="d-sm-flex justify-content-between p-2"
                        style="flex-grow: 1"
                    >
                        <div class="d-flex flex-column justify-content-center">
                            <div
                                class="d-flex align-items-start"
                                style="gap: 5px"
                            >
                                <a
                                    :href="
                                        attachment.uri ||
                                        route('attachments.show', attachment.id)
                                    "
                                    target="_blank"
                                    >{{ attachment.fileName }}
                                </a>
                                <icon
                                    v-if="!attachment.exists"
                                    v-c-tooltip="{
                                        content: __(
                                            'general.attachment.not_found',
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    icon="cil-warning"
                                />
                            </div>
                            <small class="text-muted text-nowrap">
                                {{ __('attachments.overview.size') }}:
                                {{
                                    $filters.prettyBytes(
                                        attachment.size,
                                        currentLocale,
                                    )
                                }}
                                &nbsp;&nbsp;&nbsp;{{
                                    $filters.dateTimeMedium(
                                        attachment.createdAt,
                                        currentLocale,
                                    )
                                }}
                            </small>
                        </div>
                        <div
                            v-if="!readonly"
                            class="actions d-flex justify-content-end"
                        >
                            <div v-if="canRead">
                                <a
                                    v-c-tooltip="{
                                        content: __('general.action.download'),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    :href="
                                        route(
                                            'attachments.download',
                                            attachment.id,
                                        )
                                    "
                                    class="btn btn-icon text-primary"
                                >
                                    <icon icon="cil-cloud-download" />
                                </a>
                            </div>
                            <div v-if="canEdit">
                                <a
                                    v-c-tooltip="{
                                        content: __('general.action.edit'),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    class="btn btn-icon text-primary"
                                    href="#"
                                    @click.prevent="editFilename(attachment)"
                                >
                                    <icon icon="cil-pencil" />
                                </a>
                            </div>
                            <div v-if="canDelete">
                                <div v-if="isDeletingAttachment(attachment)">
                                    <spinner />
                                </div>
                                <div v-else>
                                    <a
                                        v-c-tooltip="{
                                            content: __(
                                                'general.action.delete',
                                            ),
                                            popperOptions: {
                                                positionFixed: true,
                                            },
                                        }"
                                        :href="
                                            route(
                                                'attachments.destroy',
                                                attachment.id,
                                            )
                                        "
                                        class="btn btn-icon text-primary"
                                        @click.prevent="
                                            deleteAttachment(attachment)
                                        "
                                    >
                                        <icon icon="cil-trash" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </c-list-group-item>
            </c-list-group>
        </div>
        <div v-else>
            {{ __('attachments.overview.empty') }}
        </div>
        <dialog-modal
            :closeable="false"
            :show="!!selectedAttachment"
            @close="closeModal"
        >
            <template #title>
                {{ __('attachments.modal.update_file_name.header') }}
            </template>

            <div class="mt-2">
                <jet-input
                    ref="filename"
                    v-model="form.name"
                    :autofocus="true"
                    :horizontal="false"
                    :invalidFeedback="form.errors.name"
                    :label="__('attachments.modal.update_file_name.label')"
                    :placeholder="
                        __('attachments.modal.update_file_name.placeholder')
                    "
                    class="mt-1 block w-3/4"
                    type="text"
                    @keyup.enter.prevent="submitForm"
                >
                    <template #append>
                        <span v-if="selectedAttachment">
                            .{{ selectedAttachment.extension }}
                        </span>
                    </template>
                </jet-input>
            </div>

            <template #footer>
                <jet-button
                    :disabled="form.processing"
                    color="secondary"
                    @click.prevent="closeModal"
                >
                    {{ __('general.action.cancel') }}
                </jet-button>

                <jet-button
                    :disabled="!isFormSubmittable"
                    :is-processing="form.processing"
                    color="primary"
                    @click.prevent="submitForm"
                >
                    {{ __('general.action.save') }}
                </jet-button>
            </template>
        </dialog-modal>
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import { read } from '@popperjs/core';
import DialogModal from '@/Jetstream/DialogModal.vue';
import {
    CListGroup,
    CListGroupItem,
} from '@coreui/vue-pro/dist/esm/components/list-group/index.js';

export default {
    components: {
        CListGroupItem,
        CListGroup,
        DialogModal,
        JetButton,
        JetInput,
    },

    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        attachments: Array,
        inline: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        canRead: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            selectedAttachment: null,
            isDeleting: [],
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    methods: {
        read() {
            return read;
        },
        getFileTypeIcon(mimeType) {
            switch (mimeType) {
                case 'application/pdf':
                    return 'cil-file';
                case 'image/jpeg':
                case 'image/jpg':
                case 'image/png':
                    return;
            }
        },
        editFilename(attachment) {
            this.form = this.$inertia.form({ name: attachment.basename });
            this.selectedAttachment = attachment;
        },
        submitForm() {
            const id = this.selectedAttachment.id;

            this.form.processing = true;

            this.form.put(this.route('attachments.update', id), {
                preserveScroll: true,
                onSuccess: () => {
                    this.closeModal();

                    this.$inertia.visit(window.location.pathname, {
                        preserveScroll: true,
                    });
                },
                onError: (error) => {
                    this.$refs.filename.focus();
                },
                onFinish: () => {
                    this.form.processing = false;
                },
            });
        },
        deleteAttachment(attachment) {
            this.isDeleting = [...this.isDeleting, attachment.id];

            this.$inertia.delete(
                this.route('attachments.destroy', attachment.id),
                {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.$emit('deleted', attachment);
                    },
                    onFinish: () => {
                        this.isDeleting = this.isDeleting.filter(
                            (id) => id !== attachment.id,
                        );
                    },
                },
            );
        },
        closeModal() {
            this.selectedAttachment = null;
        },
        isDeletingAttachment(attachment) {
            return this.isDeleting.includes(attachment.id);
        },
    },
};
</script>
<style scoped>
.media {
    width: 100px;
    height: 100%;
}
</style>
