<template>
    <div v-html="description" />
</template>
<script>
export default {
    name: 'default',
    props: {
        item: {},
    },
    computed: {
        description() {
            return this.item.description;
        },
    },
};
</script>
