<template>
    <div class="d-flex align-items-center gap-2">
        <icon icon="cil-calendar" style="position: relative; top: -2px" />
        {{
            __('dashboard.header.updated_at', {
                date: date,
                time: time,
            })
        }}
    </div>
</template>
<script>
import { DateTime } from 'luxon';
import formatDate from '@/filters/date.js';

export default {
    name: 'updated-at',
    props: {
        updatedAt: {},
    },
    computed: {
        date() {
            return formatDate(
                this.updatedAt,
                { month: 'short', day: 'numeric' },
                this.currentLocale,
            );
        },
        time() {
            return formatDate(
                this.updatedAt,
                DateTime.TIME_24_SIMPLE,
                this.currentLocale,
            );
        },
    },
};
</script>
