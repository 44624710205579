<template>
    <dialog-modal
        v-if="shouldRender"
        :primary-button-label="__('general.action.yes')"
        :secondary-button-label="__('general.action.not_now')"
        :show="show"
        size="sm"
        @close="closeModal"
        @submit="handleSubmit"
    >
        <template #title
            >{{
                __('asset.workorders.modals.confirm_workorder_creation.title')
            }}
        </template>

        {{ __('asset.workorders.modals.confirm_workorder_creation.content') }}
    </dialog-modal>
</template>
<script setup>
import DialogModal from '@/Jetstream/DialogModal.vue';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { eventBus, events } from '@/eventBus.js';

const shouldRender = ref(false);
const show = ref(false);
const assetId = ref(null);

function handleSubmit() {
    if (!assetId.value) {
        return;
    }

    eventBus.$emit(
        events.openCreateWorkOrderModal,
        {
            assetId,
            type: 'corrective_maintenance',
        },
        { timeout: 300 },
    );

    closeModal();
}

function openModal() {
    shouldRender.value = true;
    nextTick(() => (show.value = true));
}

function closeModal() {
    show.value = false;
    setTimeout(() => {
        shouldRender.value = false;
    }, 300);
}

onMounted(() => {
    eventBus.$on(events.assetOperabilityUpdated, (data) => {
        if (
            data.operability === 'inoperable' &&
            data.reason === 'corrective_maintenance_required'
        ) {
            assetId.value = data.asset;
            setTimeout(() => {
                openModal();
            }, 300);
        }
    });
});

onBeforeUnmount(() => {
    eventBus.$off(events.openConfirmWorkOrderCreation);
});
</script>
