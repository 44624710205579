<template>
    <div v-html="description"/>
</template>
<script>
export default {
    name: 'WorkOrderEntry',
    props: {
        item: {},
    },
    computed: {
        description() {
            const type = this.item.properties.workorder_type;
            const code = this.item.properties.workorder_code;

            let workorder = type?.label || 'Work Order';

            let key = `activity.${this.item.event}`;

            return this.__(key, {
                workorder,
                code,
            });
        },
    },
};
</script>
