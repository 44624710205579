<template>
    <app-layout :enable-flash-message="false" class="no-main-padding">
        <page-header :title="__('workorder.overview.headline')">
            <template #action>
                <jet-button
                    v-if="hasAnyPermission('workorder.create')"
                    :disabled="isOperationInProgress"
                    class="text-nowrap"
                    type="button"
                    @click="openCreateWorkOrderModal"
                >
                    {{ __('workorder.overview.action.create') }}
                </jet-button>
            </template>

            <work-order-filter-bar
                :is-processing="isLoadingWorkOrders"
                class="mt-3"
                @clear="clearFilters"
                @update:filter="updateFilter"
            />
        </page-header>
        <div>
            <h3 class="py-2 h5">
                {{ __('workorder.overview.board.title') }}
            </h3>
            <work-order-board
                :key="`wb-${componentKey}`"
                :selected-work-order="selectedWorkOrder"
                :swimlanes="swimlanes"
                class="pb-3"
            />

            <h3 class="py-2 h5">
                {{ __('workorder.overview.records.title') }}
                <info-icon
                    :tooltip="__('assets.workorders.records.info')"
                    style="position: relative; top: -1px"
                />
            </h3>

            <work-order-records
                :key="`wr-${componentKey}`"
                :work-orders="archivedWorkOrders"
            />

            <create-work-order-modal />
            <work-order-details-modal />
            <asset-status-change-prompt-modal />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import WorkOrderDetailsModal from '@/Pages/WorkOrders/Modals/WorkOrderDetailsModal.vue';
import ConfirmWorkOrderDeletionModal from '@/Pages/WorkOrders/Modals/ConfirmWorkOrderDeletionModal.vue';
import WorkOrderRecords from '@/Pages/WorkOrders/WorkOrderRecords.vue';
import WorkOrderBoard from '@/Pages/WorkOrders/WorkOrderBoard.vue';
import CreateWorkOrderModal from '@/Pages/WorkOrders/Modals/CreateWorkOrderModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import TooltipWrapper from '@/Pages/Assets/Details/TooltipWrapper.vue';
import WorkOrderFilterBar from '@/Pages/WorkOrders/WorkOrderFilterBar.vue';
import FilterBar from '@/Components/FilterBar.vue';
import { removeEmptyProperties } from '@/utils';
import AssetSelectionField from '@/Pages/WorkOrders/WorkOrderForm/AssetSelectionField.vue';
import InfoIcon from '@/Components/InfoIcon.vue';
import ConfirmAssetStatusChangeModal from '@/Pages/Assets/Details/ConfirmAssetStatusChangeModal.vue';
import AssetStatusChangePromptModal from '@/Pages/WorkOrders/Modals/AssetStatusChangePromptModal.vue';
import FlashMessage from '@/Components/FlashMessage.vue';
import { eventBus, events } from '@/eventBus.js';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    components: {
        PageHeader,
        PageTitle,
        FlashMessage,
        AssetStatusChangePromptModal,
        ConfirmAssetStatusChangeModal,
        InfoIcon,
        AssetSelectionField,
        FilterBar,
        WorkOrderFilterBar,
        TooltipWrapper,
        JetButton,
        CreateWorkOrderModal,
        WorkOrderBoard,
        WorkOrderRecords,
        ConfirmWorkOrderDeletionModal,
        WorkOrderDetailsModal,
        AssetDetailsHeader,
        AppLayout,
    },
    data() {
        return {
            componentKey: 0,
            activeModal: null,
            selectedWorkOrder: null,
            isOperationInProgress: false,
            showCreateWorkOrderModal: false,
            isLoadingWorkOrders: false,
            activeQueryParams: null,
            assetStatusChange: {
                assetId: null,
                operability: 'inoperable',
                reason: 'corrective_maintenance_required',
            },
        };
    },
    computed: {
        workOrders() {
            return this.$page.props.workOrders?.data || [];
        },
        archivedWorkOrders() {
            return this.$page.props.archivedWorkOrders;
        },
        swimlanes() {
            return this.$page.props.swimlanes || [];
        },
        statuses() {
            return this.swimlanes.map((lane) => lane.status.value);
        },
        isSelectedWorkOrderEditable() {
            return (
                (this.selectedWorkOrder?.isEditable &&
                    this.hasAnyPermission('workorder.update')) ||
                false
            );
        },
        workOrdersRouteUrl() {
            let queryParams = Object.assign(
                {},
                removeEmptyProperties(this.activeQueryParams),
            );
            return this.route('workorders.index', queryParams);
        },
    },
    mounted() {
        eventBus.$emit(events.openWorkOrderDetailsModal, {
            workOrderId: this.$page.props.selectedWorkOrder,
        });
    },
    methods: {
        refreshWorkOrders() {
            this.$inertia.get(
                this.workOrdersRouteUrl,
                {},
                {
                    only: [...this.statuses, 'archivedWorkOrders'],
                    preserveState: true,
                    onBefore: () => {
                        this.isLoadingWorkOrders = true;
                    },
                    onFinish: () => {
                        this.forceRerender();
                        this.isLoadingWorkOrders = false;
                    },
                },
            );
        },
        forceRerender() {
            this.componentKey += 1;
        },
        openCreateWorkOrderModal() {
            eventBus.$emit(events.openCreateWorkOrderModal);
        },
        updateFilter: function (filters) {
            this.activeQueryParams = filters;
            this.refreshWorkOrders();
        },
        clearFilters() {
            this.activeQueryParams = {};
            this.refreshWorkOrders();
        },
    },
};
</script>
