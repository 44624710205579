import axios from 'axios';

const maxRetryCount = 1;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Interceptor used to handle automatic retries based on response status codes.
axios.interceptors.response.use(
    (response) => response,
    async (err) => {
        if (!err.response) {
            return Promise.reject(err);
        }

        let config = err.response.config;
        const currentRetryCount = config.retryCount || 0;

        switch (err.response.status) {
            case 419:
                if (currentRetryCount < maxRetryCount) {
                    // Refresh our session token
                    await axios.get('/sanctum/csrf-token');

                    // Set number of retries
                    let config = err.response.config;
                    config.retryCount = currentRetryCount + 1;

                    // Return a new request using the original request's configuration
                    return axios(err.response.config);
                }

                break;
            case 401:
                window.location = '/login';
                break;
            default:
                return Promise.reject(err);
        }
    }
);

export const $http = axios;
