<template>
    <app-footer
        class="d-flex justify-content-end align-items-center small"
        style="gap: 1.5rem"
    >
        <div v-if="termsOfUseLink">
            <Link :href="termsOfUseLink"
                >{{ __('general.terms_of_use') }}
            </Link>
        </div>
        <span v-if="termsOfUseLink" class="text-muted">|</span>
        <div>
            &copy; {{ new Date().getFullYear() }}
            Curie Technologies
        </div>
    </app-footer>
</template>

<script>
import AppFooter from '@/Components/AppFooter.vue';
import { Link } from '@inertiajs/vue3';

export default {
    name: 'TheFooter',
    components: { Link, AppFooter },
    computed: {
        termsOfUseLink() {
            return this.$page.props.termsOfUseLink;
        },
    },
};
</script>
