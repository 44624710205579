<template>
    <div>
        <multi-select-dropdown
            v-if="multiSelect"
            :disabled="isDisabled"
            :horizontal="horizontal"
            :invalidFeedback="invalidFeedback"
            :is-form-field="true"
            :label="label"
            :model-value="modelValue"
            :options="availableOptions || []"
            :placeholder="placeholder"
            :required="required"
            @update:model-value="$emit('update:modelValue', $event)"
        >
            <template #label="{ option, show }">
                <slot :option="option" :show="show" name="label" />
            </template>
            <template #actions>
                <div class="d-flex">
                    <button-add-new
                        v-if="showAction"
                        @click.native="handleClick"
                    >
                        {{ actionButtonTitle || __('general.action.add_new') }}
                    </button-add-new>
                </div>
            </template>
        </multi-select-dropdown>
        <jet-select
            v-else
            :disabled="isDisabled"
            :horizontal="horizontal"
            :invalidFeedback="invalidFeedback"
            :label="label"
            :model-value="modelValue"
            :options="availableOptions || []"
            :placeholder="placeholder"
            :required="required"
            @update:model-value="$emit('update:modelValue', $event)"
        >
            <template #actions>
                <button-add-new v-if="showAction" @click.native="handleClick">
                    {{ actionButtonTitle || __('general.action.add_new') }}
                </button-add-new>
            </template>
        </jet-select>
        <slot name="modal" v-bind:modal="{ loadOptions }">
            <create-dropdown-option-modal
                v-if="showAction"
                :createRoute="routes.store"
                :form-fields="formFields"
                :label="label"
                :show="isModalVisible"
                :title="title || __('general.action.add_new')"
                @cancel="handleModalCancel"
                @success="handleModalSuccess"
            >
                <template #form="{ form, label, show }">
                    <slot
                        :form="form"
                        :label="label"
                        :show="show"
                        name="form"
                    />
                </template>
            </create-dropdown-option-modal>
        </slot>
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';
import CreateDropdownOptionModal from '@/Pages/Assets/Modals/CreateDropdownOptionModal.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import { $http } from '@/bootstrap.js';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import DropdownDivider from '@/Components/DropdownDivider.vue';

export default {
    props: {
        title: String,
        label: String,
        actionButtonTitle: String,
        formFields: Object,
        multiSelect: {
            type: Boolean,
            default: false,
        },
        options: Array,
        required: {
            type: Boolean,
            default: false,
        },
        modelValue: [Array, String],
        horizontal: {
            default: true,
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        disabled: Boolean,
        showAction: {
            type: Boolean,
            default: false,
        },
        routes: Object,
        dependentOn: String,
        loadOnStart: {
            type: Boolean,
            default: true,
        },
        optionTransformer: {
            type: Function,
            default: (option) => ({
                value: option.id,
                label: option.name,
            }),
        },
    },
    emits: ['update:modelValue', 'open'],
    components: {
        DropdownDivider,
        MultiSelectDropdown,
        ButtonAddNew,
        JetSelect,
        CreateDropdownOptionModal,
    },
    data() {
        return {
            isLoading: false,
            isModalVisible: false,
            availableOptions: [],
        };
    },
    computed: {
        isDisabled() {
            if (this.dependentOn !== undefined && !this.dependentOn) {
                return true;
            }
            return this.isLoading;
        },
    },
    watch: {
        dependentOn: function () {
            this.loadOptions(this.modelValue);
        },
    },
    mounted() {
        if (this.dependentOn === undefined && this.loadOnStart) {
            this.loadOptions();
        }
        this.availableOptions = this.options;
    },
    methods: {
        handleClick() {
            this.$emit('open');
            this.isModalVisible = true;
        },
        handleModalCancel() {
            this.isModalVisible = false;
        },
        handleModalSuccess(data) {
            this.isModalVisible = false;
            this.loadOptions(data.id);
        },
        loadOptions(selectedOptionId) {
            this.isLoading = true;

            $http(this.routes.index)
                .then((response) => {
                    if (response.data.data) {
                        this.availableOptions = this.transformOptions(
                            response.data.data,
                        );

                        if (selectedOptionId) {
                            selectedOptionId = this.multiSelect
                                ? [selectedOptionId]
                                : selectedOptionId;
                            this.$emit('update:modelValue', selectedOptionId);
                        } else if (this.availableOptions.length === 1) {
                            selectedOptionId = this.multiSelect
                                ? [this.availableOptions[0].value]
                                : this.availableOptions[0].value;
                            this.$emit('update:modelValue', selectedOptionId);
                        } else if (this.dependentOn) {
                            this.$emit('update:modelValue', null);
                        }
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        transformOptions(options) {
            return options.map(this.optionTransformer);
        },
    },
};
</script>
