<template>
    <c-form-check
        :description="description"
        :invalidFeedback="invalidFeedback"
        :isValid="isValid"
        :label="label"
        :model-value="modelValue"
        :required="required"
        @update:modelValue="$emit('update:modelValue', $event)"
    />
</template>

<script>
import {CFormCheck} from "@coreui/vue/dist/esm/components/form/index.js";

export default {
    name: 'JetCheckbox',
    components: {CFormCheck},

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            default: null,
        },
        label: String,
        invalidFeedback: {
            type: String,
            default: '',
        },
        horizontal: {
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
    },

    computed: {
        isValid() {
            return this.invalidFeedback === '' ? null : false;
        },
    },
};
</script>
