<template>
    <div class="form-group">
        <label v-if="label" v-html="label" />
        <input-radio-group
            :model-value="modelValue"
            :options="options"
            @update:model-value="valueChanged"
        />
    </div>
</template>
<script>
import InputRadioGroup from '@/Components/InputRadioGroup.vue';

export default {
    name: 'JetRadioGroup',
    components: { InputRadioGroup },

    props: {
        label: String,
        name: {
            type: String,
            default: '',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        modelValue: String,
        options: {
            type: Array,
            default: [],
        },
    },

    emits: ['update:modelValue'],

    computed: {
        isValid() {
            return !this.invalidFeedback ? null : false;
        },
    },

    methods: {
        valueChanged(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
</script>
