<template>
    <dialog-modal
        v-if="shouldRender"
        :primary-button-label="__('general.action.yes_discard_changes')"
        :show="showModal"
        type="danger"
        @close="closeModal"
        @submit="onSubmit"
    >
        <template #title
            >{{ __('general.discard_changes.modal.title') }}
        </template>
        {{ __('general.discard_changes.modal.description') }}
    </dialog-modal>
</template>
<script setup>
import DialogModal from '@/Jetstream/DialogModal.vue';
import { eventBus, events } from '@/eventBus.js';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

const emit = defineEmits(['close']);
const shouldRender = ref(false);
const showModal = ref(false);
const onConfirm = ref(null);

const closeModal = async () => {
    return new Promise((resolve) => {
        showModal.value = false;

        setTimeout(() => {
            resolve();
            shouldRender.value = false;
        }, 150);

        emit('close');
    });
};

const onSubmit = async () => {
    await closeModal();

    if (onConfirm.value) {
        onConfirm.value();
    }
};

onMounted(() => {
    eventBus.$on([events.openConfirmDiscardChangesModal], (data) => {
        shouldRender.value = true;
        onConfirm.value = data.onConfirm;

        nextTick(() => {
            showModal.value = true;
        });
    });
});

onBeforeUnmount(() => {
    eventBus.$off([events.openConfirmDiscardChangesModal]);
    onConfirm.value = null;
});
</script>
