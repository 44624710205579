<template>
    <info
        v-c-tooltip="{
            content: tooltip,
            popperOptions: {
                positionFixed: true,
            },
        }"
    />
</template>

<script>
import Info from '@/../icons/Info.vue';

export default {
    components: { Info },
    props: ['tooltip'],
};
</script>
