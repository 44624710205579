<template>
    <auth-layout>
        <container fluid>
            <row>
                <column>
                    <flash-message
                        class="position-absolute"
                        style="left: 15px; right: 15px; top: 80px"
                    />
                </column>
            </row>
        </container>
        <container
            class="align-items-center min-vh-100"
            style="overflow: hidden"
        >
            <row class="min-vh-100 justify-content-center align-items-center">
                <column md="6">
                    <card class="p-4 m-0">
                        <h1>
                            {{ __('auth.reset_password.expired.header') }}
                        </h1>
                        <p class="text-muted">
                            {{ __('auth.reset_password.expired.description') }}
                        </p>
                        <div class="d-flex justify-content-end">
                            <jet-button
                                :is-processing="form.processing"
                                class="px-4"
                                color="primary"
                                type="submit"
                                @click.stop="resendLink"
                                >{{
                                    __('auth.invitation.expired.action.title')
                                }}
                            </jet-button>
                        </div>
                    </card>
                </column>
            </row>
        </container>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import FlashMessage from '@/Components/FlashMessage.vue';
import Container from '@/Components/Container.vue';

import Card from '@/Components/Card.vue';

export default {
    components: {
        Card,

        Container,
        FlashMessage,
        JetButton,
        AuthLayout,
    },
    data() {
        return {
            form: this.$inertia.form({
                token: this.$page.props.token,
            }),
        };
    },
    methods: {
        resendLink() {
            this.form.get(
                this.route('invitation.request-link', {
                    token: this.form.token,
                }),
            );
        },
    },
};
</script>
