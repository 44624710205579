export default {
    data() {
        return {
            isSmallScreen: document.body.clientWidth < 576,
        };
    },
    created() {
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            this.isSmallScreen = document.body.clientWidth < 576;
        },
    },
};
