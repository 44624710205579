<template>
    <Switch
        :class="[{ onboarded: disabled }]"
        :disabled="disabled"
        :model-value="modelValue"
        class="operability-switch"
        @update:model-value="onChange"
    />
</template>
<script>
import Switch from '@/Components/Switch.vue';

export default {
    name: 'AssetOperabilitySwitch',
    components: { Switch },
    props: {
        modelValue: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
    },
    emits: ['changed', 'update:modelValue'],
    methods: {
        onChange(value) {
            this.$emit('update:modelValue', value);
            this.$emit('changed', value);
        },
    },
};
</script>
