<template>
    <div>
        <div
            :class="{ 'is-invalid': !!invalidFeedback }"
            class="p-4 filepond-upload-container"
        >
            <icon class="filepond-upload-icon" icon="cil-cloud-upload" />
            <file-pond
                ref="pond"
                :allow-multiple="true"
                :allow-remove="true"
                :allow-replace="false"
                :allow-revert="true"
                :label_button-process-item="
                    __('general.file_upload.label_button_process_item')
                "
                :label_button_abort_item_load="
                    __('general.file_upload.label.button_abort_item_load')
                "
                :label_button_abort_item_processing="
                    __('general.file_upload.label.button_abort_item_processing')
                "
                :label_button_remove_item="
                    __('general.file_upload.label.button_remove_item')
                "
                :label_button_retry_item_load="
                    __('general.file_upload.label.button_retry_item_load')
                "
                :label_button_retry_item_processing="
                    __('general.file_upload.label.button_retry_item_processing')
                "
                :label_button_undo_item_processing="
                    __('general.file_upload.label.button_undo_item_processing')
                "
                :label_file_load_error="
                    __('general.file_upload.label.file_load_error')
                "
                :label_file_loading="
                    __('general.file_upload.label.file_loading')
                "
                :label_file_processing="
                    __('general.file_upload.label.file_processing')
                "
                :label_file_processing_aborted="
                    __('general.file_upload.label.file_processing_aborted')
                "
                :label_file_processing_complete="
                    __('general.file_upload.label.file_processing_complete')
                "
                :label_file_processing_error="labelFileProcessingError"
                :label_file_processing_revert_error="
                    __('general.file_upload.label.file_processing_revert_error')
                "
                :label_file_remove_error="
                    __('general.file_upload.label.file_remove_error')
                "
                :label_file_size_not_available="
                    __('general.file_upload.label.file_size_not_available')
                "
                :label_file_waiting_for_size="
                    __('general.file_upload.label.file_waiting_for_size')
                "
                :label_idle="__('general.file_upload.label.idle')"
                :label_invalid_field="
                    __('general.file_upload.label.invalid_field')
                "
                :label_tap_to_cancel="
                    __('general.file_upload.label.tap_to_cancel')
                "
                :label_tap_to_retry="
                    __('general.file_upload.label.tap_to_retry')
                "
                :label_tap_to_undo="__('general.file_upload.label.tap_to_undo')"
                :server="serverConfig"
                accepted-file-types="image/jpeg, image/png, application/pdf"
                credits=""
                name="upload"
                @addfilestart="onAddFileStart"
                @processfiles="onFilesProcessed"
                @removefile="onRemoveFile"
            />
        </div>
        <div
            v-if="invalidFeedback"
            class="invalid-feedback"
            v-text="invalidFeedback"
        />
    </div>
</template>
<script>
import vueFilePond from 'vue-filepond';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { fileHash } from '@/filehash.js';
import { $http } from '@/bootstrap.js'; // Create component

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
);

export default {
    components: {
        FilePond,
    },

    props: {
        modelValue: {},
        invalidFeedback: {
            type: String,
            default: '',
        },
    },

    data: function () {
        return {
            serverConfig: {
                url: this.route('attachments.store'),
                headers: {
                    'X-XSRF-TOKEN': this.getCookie('XSRF-TOKEN'),
                },
                process: async (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort,
                ) => {
                    try {
                        const response = await $http.post(
                            this.route('attachments.store'),
                            {
                                content_type: file.type,
                                size: file.size,
                                file_name: file.name,
                                checksum: await fileHash(file),
                            },
                        );

                        let formData = new FormData();
                        Object.entries(response.data.fields).forEach(function ([
                            key,
                            value,
                        ]) {
                            formData.append(key, value);
                        });

                        formData.append('file', file);

                        await $http.post(response.data.url, formData, {
                            onUploadProgress: (e) => {
                                progress(
                                    e.event.lengthComputable,
                                    e.loaded,
                                    e.total,
                                );
                            },
                        });
                        load(response.data.id);
                    } catch (e) {
                        const message =
                            e.response?.data?.message || e.message || e;
                        error(message);
                        this.$emit('upload-completed');
                    }
                },
            },
        };
    },

    methods: {
        getCookie: function (cname) {
            let name = cname + '=';
            let cookieArray = document.cookie.split(';');

            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];

                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }

                if (cookie.indexOf(name) === 0) {
                    return decodeURIComponent(
                        cookie.substring(name.length, cookie.length),
                    );
                }
            }
            return '';
        },
        onAddFileStart() {
            this.$emit('upload-started');
        },
        onFilesProcessed() {
            const ids = this.$refs.pond.getFiles().map((file) => {
                return file.serverId;
            });

            this.$emit('update:model-value', ids);
            this.$emit('upload-completed');
        },
        onRemoveFile() {
            const ids = this.$refs.pond.getFiles().map((file) => {
                return file.serverId;
            });

            this.$emit('update:model-value', ids);
        },
        labelFileProcessingError(error) {
            return error.body;
        },
    },
};
</script>
