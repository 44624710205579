<template>
    <div
        ref="container"
        :class="{ 'c-show': show }"
        class="multi-select date-filter"
        style="user-select: none; cursor: pointer"
        tabindex="0"
        @click="toggleVisibility"
        @keydown.esc="show = false"
    >
        <span
            :class="{
                'justify-content-between': modelValue?.start || modelValue?.end,
            }"
            class="multi-select-selection d-flex justify-content-start align-items-center"
            style="min-height: 2rem; gap: 5px"
        >
            <icon name="cil-calendar" />
            <span class="text-nowrap">{{ selectedDateRange }}</span>
        </span>
        <div class="multi-select-options" style="width: auto" @click.stop="">
            <date-picker
                ref="calendar"
                v-model.range="dateRange"
                :disabled-dates="disabledDates"
                :first-day-of-week="2"
                :locale="currentLocale"
                class="w-100"
                is-expanded
                trim-weeks
                @update:model-value="onDateSelect"
            />
        </div>
    </div>
</template>
<script>
import { onClickOutside } from '@vueuse/core';
import { DatePicker } from 'v-calendar';
import { DateTime } from 'luxon';
import fullDate from '@/filters/fullDate.js';

export default {
    name: 'date-filter',
    components: { DatePicker },
    props: {
        modelValue: { start: Date, end: Date },
        attributes: Array,
        disabledDates: {
            type: Array,
            default: () => [],
        },
        selectionMode: {
            type: String,
            default: 'daily',
        },
        label: String,
    },
    data() {
        return {
            show: false,
            dateRange: {
                start: null,
                end: null,
            },
        };
    },
    computed: {
        appliedAttributes() {
            return this.attributes && this.attributes.length
                ? this.attributes
                : [
                      {
                          key: 'selected',
                          highlight: true,
                          dates: this.modelValue,
                      },
                  ];
        },
        selectedDateRange() {
            let formattedStartDate = fullDate(
                this.modelValue?.start,
                this.currentLocale,
            );
            let formattedEndDate = fullDate(
                this.modelValue?.end,
                this.currentLocale,
            );

            if (!formattedStartDate && !formattedEndDate) {
                return this.label;
            }

            if (!formattedEndDate) {
                return '>= ' + formattedStartDate;
            }

            if (!formattedStartDate) {
                return '<= ' + formattedEndDate;
            }

            return `${formattedStartDate} - ${formattedEndDate}`;
        },
    },

    watch: {
        modelValue: {
            immediate: true,
            handler(value) {
                this.dateRange = {
                    start: !!value?.start ? new Date(value?.start) : null,
                    end: !!value?.end ? new Date(value?.end) : null,
                };
            },
        },
    },
    mounted() {
        onClickOutside(this.$refs.container, this.hideElement);
    },
    methods: {
        hideElement() {
            this.show = false;
        },
        toggleVisibility() {
            if (this.disabled) {
                this.show = false;
            }
            this.show = !this.show;
        },
        onDateSelect(range) {
            if (!range?.start || !range?.end) {
                return;
            }

            this.$emit('update:modelValue', {
                start: DateTime.fromJSDate(range.start).toFormat('yyyy-MM-dd'),
                end: DateTime.fromJSDate(range.end).toFormat('yyyy-MM-dd'),
            });
        },
    },
};
</script>
