<template>
    <a :href="link" target="_blank">
        <small v-if="size === 'sm'">{{ email }}</small>
        <span v-else>{{ email }}</span>
    </a>
</template>
<script>
export default {
    name: 'EmailLink',
    props: {
        email: String,
        size: {
            type: String,
        }
    },
    computed: {
        link() {
            return 'mailto:' + this.email;
        },
    },
};
</script>
