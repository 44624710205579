<template>
    <div class="d-flex justify-content-between align-items-center pb-3">
        <div>
            <h4 class="mb-0">
                <slot name="title"></slot>
            </h4>

            <p class="small text-muted" v-if="!!$slots['description']">
                <slot name="description"></slot>
            </p>
        </div>
        <div>
            <slot name="aside"></slot>
        </div>
    </div>
</template>
