import './bootstrap';
import './utils';

import 'v-calendar/style.css';
import '../scss/style.scss';

// Import modules...
import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import { ZiggyVue } from 'ziggy-js';
import hasAnyPermission from '@/mixins/hasAnyPermission.js';
import i18n from '@/mixins/i18n.js';
import hasFeature from '@/mixins/hasFeature.js';
import fullDate from '@/filters/fullDate.js';
import CoreuiVue from '@coreui/vue';
import relativeDate from '@/filters/relativeDate.js';
import locale from '@/mixins/locale.js';
import dateTimeMedium from '@/filters/dateTimeMedium.js';
import setupErrorReporting from '@/setupErrorReporting.js';
import shortDate from '@/filters/shortDate.js';
import time from '@/filters/time.js';
import linkify from '@/filters/linkify.js';
import nl2br from '@/filters/nl2br.js';
import apiDate from '@/mixins/apiDate.js';
import isFormSubmittable from '@/mixins/isFormSubmittable.js';
import dateFilter from '@/Pages/ReportingLight/DateFilter.vue';
import user from '@/mixins/user.js';
import duration from '@/filters/duration.js';
import { iconsSet as icons } from '../icons/icons.js';

import '@formatjs/intl-durationformat/polyfill';
import NewLineToBreak from '@/Components/NewLineToBreak.vue';
import { createPinia } from 'pinia';
import { setupCalendar } from 'v-calendar';
import Icon from '@/Components/Icon.vue';
import Spinner from '@/Components/Spinner.vue';
import Column from '@/Components/Column.vue';
import Row from '@/Components/Row.vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import Vapor from 'laravel-vapor';
import prettyBytes from '@/filters/prettyBytes.js';
import detectSmallScreen from '@/mixins/detectSmallScreen.js';
// import setupErrorReporting from '@/setupErrorReporting';

window.Vapor = Vapor;
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

const appName = import.meta.env.VITE_APP_NAME || 'petiteC';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*Page.vue'),
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(CoreuiVue)
            .use(createPinia())
            .use(setupCalendar, {})
            .use(ZiggyVue)
            .component('Row', Row)
            .component('Column', Column)
            .component('Icon', Icon)
            .component('Spinner', Spinner)
            .component('Link', Link)
            .component('nl2br', NewLineToBreak)

            .mixin({ methods: { cdnAssetPath: window.Vapor.asset } })
            .mixin(hasAnyPermission)
            .mixin(locale)
            .mixin(i18n)
            .mixin(hasFeature)
            .mixin(apiDate)
            .mixin(user)
            .mixin(isFormSubmittable)
            .mixin(detectSmallScreen)
            .provide('icons', icons);

        app.config.globalProperties.$route = route;
        app.config.globalProperties.$filters = {
            fullDate,
            relativeDate,
            dateTimeMedium,
            date: dateFilter,
            shortDate,
            duration,
            time,
            linkify,
            nl2br,
            prettyBytes,
        };

        app.mount(el);
        setupErrorReporting(app);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
