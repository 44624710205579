<template>
    <c-badge
        :class="{ 'badge-circle': type === 'circle' }"
        :color="color"
        :text-color="textColor"
        class="flex-shrink-0 flex-grow-0"
    >
        <slot />
    </c-badge>
</template>
<script setup>
import { CBadge } from '@coreui/vue/dist/esm/components/badge/index.js';
import { computed } from 'vue';

const props = defineProps(['color', 'type']);

const textColor = computed(() => {
    return props.color === 'danger' ? 'light' : 'body-emphasis';
});
</script>
