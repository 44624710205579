<template>
    <TheContainer>
        <slot />
        <flash-message />
        <!-- Modals used in the application -->
        <petitec-id-scanner />
        <!-- End of Modals -->
    </TheContainer>
</template>

<script>
import TheContainer from '@/Containers/TheContainer.vue';
import FlashMessage from '@/Components/FlashMessage.vue';
import PetitecIdScanner from '@/Components/PetitecIdScanner.vue';

export default {
    components: {
        PetitecIdScanner,
        TheContainer,
        FlashMessage,
    },

    props: {
        enableFlashMessage: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        logout() {
            this.$inertia.post(route('logout'));
        },
    },
};
</script>
