<template>
    <app-header>
        <toggler @click="sidebar.toggleVisible()" />
        <header-nav>
            <nav-item>
                <the-header-dropdown-account />
            </nav-item>
            <nav-item>
                <notification-center />
            </nav-item>
            <nav-item
                v-if="!!supportLink"
                v-c-tooltip="{
                    content: __('navigation.support'),
                    placement: 'bottom',
                }"
            >
                <nav-link :href="supportLink" text="?" />
            </nav-item>
            <nav-item>
                <language-selector />
            </nav-item>
            <nav-item
                class="d-none d-sm-flex align-items-center clickable"
                @click.stop="$inertia.visit('/')"
            >
                <span class="d-block" style="width: 20px; height: 5px" />
                <icon
                    class="d-md-block d-none"
                    icon="logoLarge"
                    style="width: 80px; height: 28px"
                />
            </nav-item>
        </header-nav>
    </app-header>
</template>

<script setup>
import NotificationCenter from '@/Components/NotificationCenter/NotificationCenter.vue';
import LanguageSelector from './LanguageSelector.vue';
import HeaderNav from '@/Components/HeaderNav.vue';
import Toggler from '@/Components/Toggler.vue';
import NavItem from '@/Components/NavItem.vue';
import NavLink from '@/Components/NavLink.vue';
import Icon from '@/Components/Icon.vue';
import TheHeaderDropdownAccount from '@/Containers/HeaderDropdownAccount.vue';
import AppHeader from '@/Components/AppHeader.vue';
import { useSidebarStore } from '@/stores/sidebar.js';
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const sidebar = useSidebarStore();
const supportLink = computed(() => usePage().props.supportLink);
</script>
<style scoped>
.header-support-link {
    width: 3.25rem;
    height: 36px;
    justify-content: center;
}

.header-support-link span {
    font-size: 1.1rem;
    line-height: 1rem;
    display: block;
}

:deep(.dropdown-toggle) {
    padding: 0 5px !important;
}
</style>
