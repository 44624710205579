<template>
    <form-group
        :horizontal="horizontal"
        :label="label"
        :label-classes="[{ required }]"
    >
        <component
            :is="$slots.prepend || $slots.append ? 'c-input-group' : 'div'"
        >
            <c-input-group-text v-if="$slots.prepend" :class="prependClasses">
                <slot name="prepend" />
            </c-input-group-text>
            <c-form-input
                :id="id"
                ref="input"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                :disabled="disabled || readonly"
                :invalid="!isValid"
                :model-value="modelValue"
                :name="name"
                :placeholder="placeholder"
                :readonly="readonly"
                :required="required"
                :type="type"
                @blur="$emit('blur', $event)"
                @change="$emit('change', $event)"
                @focus="$emit('focus', $event)"
                @keyup="$emit('keyup', $event)"
                @update:modelValue="$emit('update:modelValue', $event)"
            />
            <c-input-group-text v-if="$slots.append" :class="appendClasses">
                <slot name="append" />
            </c-input-group-text>
            <slot
                v-if="$slots.appendButton"
                :class="appendClasses"
                name="appendButton"
            />
        </component>
        <div
            v-if="invalidFeedback"
            class="text-danger small"
            v-text="invalidFeedback"
        />
        <div v-if="description" class="form-text" v-html="description" />
    </form-group>
</template>

<script>
import {
    CFormInput,
    CInputGroup,
    CInputGroupText,
} from '@coreui/vue/dist/esm/components/form/index.js';
import FormGroup from '@/Components/FormGroup.vue';
import { CFormFeedback } from '@coreui/vue-pro/dist/esm/components/form/index.js';
import InvalidFeedback from '@/Components/InvalidFeedback.vue';

export default {
    name: 'JetInput',
    emits: ['update:modelValue', 'blur', 'change', 'focus', 'keyup'],
    components: {
        InvalidFeedback,
        CFormFeedback,
        FormGroup,
        CInputGroup,
        CInputGroupText,
        CFormInput,
    },

    props: {
        id: String,
        name: String,
        label: String,
        info: String,
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        autocomplete: String,
        inputmode: String,
        modelValue: String,
        horizontal: {
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        prependClasses: {
            type: String,
        },
        appendClasses: {
            type: String,
        },
    },

    computed: {
        isValid() {
            return !this.invalidFeedback;
        },
    },

    mounted() {},

    watch: {
        autofocus: function (value) {
            if (value) {
                this.$refs.input.$el.getElementsByTagName('input')[0].focus();
            }
        },
    },

    methods: {
        focus() {
            this.$refs.input.$el.focus();
        },
    },
};
</script>
