<template>
    <c-sidebar-toggler
        style="z-index: 999"
        @click="sidebar.toggleUnfoldable()"
    />
    <c-close-button class="d-lg-none" @click="sidebar.close()" />
</template>
<script setup>
import { CSidebarToggler } from '@coreui/vue/dist/esm/components/sidebar/index.js';
import { useSidebarStore } from '@/stores/sidebar.js';
import { CCloseButton } from '@coreui/vue-pro/dist/esm/components/close-button/index.js';

const sidebar = useSidebarStore();
</script>
