<template>
    <c-sidebar
        :class="{ show: sidebar.visible }"
        :unfoldable="sidebar.unfoldable"
        :visible="sidebar.visible"
        position="fixed"
        @hide="onHide"
        @visible-change="onVisibleChange"
    >
        <slot />
    </c-sidebar>
</template>
<script setup>
import { CSidebar } from '@coreui/vue/dist/esm/components/sidebar/index.js';
import { useSidebarStore } from '@/stores/sidebar';

const sidebar = useSidebarStore();

const props = defineProps(['minimize']);

const onVisibleChange = (visible) => {
    sidebar.toggleVisible(visible);
};

const onHide = () => {
    document
        .getElementsByClassName('sidebar')[0]
        ?.scrollTo({ top: 0, behavior: 'instant' });
};
</script>
