<template>
    <collapsable-filter-bar
        :active="activeFilters.isDirty"
        :is-processing="isProcessing"
        @clear="clearFilters"
    >
        <location-filter :form="activeFilters" />
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import TruncatedLabel from '@/Pages/WorkOrders/TruncatedLabel.vue';
import Icon from '@/Components/Icon.vue';
import DateRangeFilter from '@/Components/DateRangeFilter.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import UserFilter from '@/Components/UserFilter.vue';

export default {
    components: {
        UserFilter,
        SearchFilter,
        Icon,
        TruncatedLabel,
        DateRangeFilter,
        LocationFilter,
        CollapsableFilterBar,
        MultiSelectDropdown,
    },
    props: {
        isProcessing: Boolean,
    },
    emits: ['change'],
    data() {
        return {
            activeFilters: this.$inertia.form({
                locations: [],
                specificLocations: [],
            }),
        };
    },
    mounted() {
        this.readValuesFromUrl();

        this.$watch(
            (vm) => [
                vm.activeFilters.locations,
                vm.activeFilters.specificLocations,
            ],
            () => {
                this.$emit('change', this.activeFilters.data());
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },
    methods: {
        resetFilters() {
            this.activeFilters.reset();
        },
        readValuesFromUrl() {
            const { locations, specificLocations } = this.route().params;

            this.activeFilters.locations = locations || [];
            this.activeFilters.specificLocations = specificLocations || [];
        },
        clearFilters() {
            this.resetFilters();
        },
    },
};
</script>
