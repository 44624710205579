<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasFeature('asset-status-check') &&
            hasAnyPermission('asset.status_check.create')
        "
        :is-processing="form.processing"
        :primary-button-label="__('general.action.confirm')"
        :show="show"
        @close="closeModal"
        @submit="submitForm"
    >
        <template #title>
            {{ __('check_asset_status.modal.header') }}
        </template>
        <div v-if="isLoading" class="d-flex justify-content-center mt-4">
            <spinner size="md" />
        </div>
        <form v-else @submit.prevent="submitForm">
            <form-group
                :horizontal="{ label: 'col-md-6', input: 'col-md-6' }"
                :label="__('check_asset_status.modal.operability.label')"
                label-classes="font-weight-bold"
            >
                <asset-operability-switch v-model="form.operability" />
            </form-group>
            <form-group
                :horizontal="{ label: 'col-md-6', input: 'col-md-6' }"
                :label="__('check_asset_status.modal.usage_category.label')"
                label-classes="font-weight-bold"
            >
                <usage-category-selection-dropdown
                    v-model="form.usageCategory"
                    :disabled="isOperationInProgress"
                    :usage-categories="usageCategories"
                />
            </form-group>

            <form-group
                v-if="showReasonSelection"
                :horizontal="false"
                :label="
                    __(
                        'check_asset_status.modal.reason_for_status_change.label',
                    )
                "
                label-classes="font-weight-bold"
            >
                <input-radio-group
                    v-model="form.reason"
                    :invalidFeedback="form.errors.reason"
                    :options="reasons"
                />
            </form-group>
            <jet-text-area
                v-if="showAdditionalInformation"
                v-model="form.additionalInformation"
                :horizontal="false"
                :invalidFeedback="form.errors.additionalInformation"
                :label="
                    __('check_asset_status.modal.additional_information.label')
                "
                :maxlength="80"
                :placeholder="
                    __(
                        'check_asset_status.modal.additional_information.placeholder',
                    )
                "
                :rows="4"
                :scrollIntoView="true"
                label-classes="font-weight-bold"
            />
            <row v-if="form.isDirty">
                <column>
                    <alert class="badge-warning mb-0" color="warning">
                        <div
                            v-html="
                                __(
                                    'check_asset_status.modal.content.confirm_change',
                                    {
                                        status: statusName,
                                    },
                                )
                            "
                        />
                    </alert>
                </column>
            </row>
            <row v-if="form.errors">
                <column>
                    <div v-for="(message, key) in form.errors" :key="key">
                        <small class="text-danger">{{ message }}</small>
                    </div>
                </column>
            </row>
        </form>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import { eventBus, events } from '@/eventBus';
import AssetOperabilitySwitch from '@/Pages/Assets/Details/AssetOperabilitySwitch.vue';
import JetTextArea from '@/Jetstream/Textarea.vue';
import UsageCategorySelectionDropdown from '@/Pages/Assets/Details/UsageCategorySelectionDropdown.vue';

import InputRadioGroup from '@/Components/InputRadioGroup.vue';
import Alert from '@/Components/Alert.vue';
import { $http } from '@/bootstrap.js';
import FormLabel from '@/Components/FormLabel.vue';
import FormGroup from '@/Components/FormGroup.vue';

export default {
    props: {
        assetId: {},
    },

    emits: ['performed'],

    components: {
        FormGroup,
        FormLabel,
        Alert,
        InputRadioGroup,

        UsageCategorySelectionDropdown,
        JetTextArea,
        AssetOperabilitySwitch,
        DialogModal,
        JetButton,
    },

    data() {
        return {
            shouldRender: false,
            show: false,
            isOperationInProgress: false,
            isLoading: false,
            initialOperabilityValue: true,
            formData: null,
            form: this.$inertia.form({
                operability: undefined,
                usageCategory: undefined,
                reason: undefined,
                additionalInformation: undefined,
            }),
        };
    },

    mounted() {
        eventBus.$on([events.openCreateStatusCheckModal], (data) => {
            this.form.reset();
            this.shouldRender = true;
            this.loadFormData();

            this.$nextTick(() => {
                this.show = true;
            });
        });
    },

    beforeUnmount() {
        eventBus.$off([events.openCreateStatusCheckModal]);
    },

    computed: {
        usageCategories() {
            return this.formData?.usageCategories || [];
        },
        reasons() {
            return this.formData?.reasons || [];
        },
        currentAssetUsageCategory() {
            return this.usageCategories.find(
                (item) => item.value === this.form.usageCategory,
            );
        },
        currentOperability() {
            return this.form.operability ? 'operable' : 'inoperable';
        },
        hasOperabilityChanged() {
            // Needed to determine whether to show the optional section "reason for change"
            return this.initialOperabilityValue !== this.form.operability;
        },
        statusName() {
            let operability = this.__(
                `general.assetstatus.${this.currentOperability}`,
            );
            return `${operability}/${this.currentAssetUsageCategory?.label}`;
        },
        showReasonSelection() {
            return (
                this.form.operability === false && this.hasOperabilityChanged
            );
        },
        showAdditionalInformation() {
            return true;
        },
    },

    methods: {
        loadFormData() {
            if (!this.assetId || this.isLoading) {
                return;
            }

            this.isLoading = true;

            $http
                .get(
                    this.route('assets.status-checks.create', {
                        asset: this.assetId,
                    }),
                )
                .then((resp) => {
                    this.formData = resp.data;

                    const { operability, usageCategory } =
                        resp.data.asset.status;

                    this.initialOperabilityValue = operability === 'operable';

                    this.form = this.$inertia.form({
                        operability: this.initialOperabilityValue,
                        reason: 'corrective_maintenance_required',
                        usageCategory: usageCategory,
                        additionalInformation: undefined,
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        submitForm() {
            if (this.form.processing) {
                return;
            }

            this.form
                .transform(this.transformData)
                .post(this.route('assets.status-checks.store', this.assetId), {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.closeModal();

                        eventBus.$emit(events.updateNotifications);

                        if (this.hasOperabilityChanged) {
                            eventBus.$emit(events.assetOperabilityUpdated, {
                                asset: this.assetId,
                                ...this.transformData(this.form.data()),
                            });
                        }

                        this.$emit('performed');
                    },
                    onFinish: () => {
                        this.isOperationInProgress = false;
                    },
                });
        },
        transformData(data) {
            let operability = data.operability ? 'operable' : 'inoperable';

            let dataToSend = {
                usageCategory: data.usageCategory,
                operability,
                additionalInformation: data.additionalInformation || null,
            };

            if (data.operability) {
                return dataToSend;
            }

            if (!this.hasOperabilityChanged) {
                return dataToSend;
            }

            dataToSend.reason = data.reason;
            return dataToSend;
        },
        closeModal() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
    },
};
</script>
