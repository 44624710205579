<template>
    <app-layout>
        <asset-details-header />

        <div class="d-flex justify-content-between align-items-center mt-3">
            <activity-filterbar
                :is-processing="isLoadingActivities"
                class="w-100"
                @change="updateFilter"
            />
            <jet-button
                :disabled="true"
                class="d-none d-sm-flex"
                color="secondary"
                >{{ __('general.action.export') }}
            </jet-button>
        </div>

        <div class="py-3">
            <ActivityLog
                :activities="activities"
                :fields="fields"
                context="asset"
                @update:sorter-value="updateSorting"
            />
            <pagination
                :only="['activities']"
                :paginator="activities"
                :query="activeQueryParams"
                class="mt-4"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';
import Pagination from '@/Components/Pagination.vue';
import TestRecordsFilterbar from '@/Pages/TestRecords/TestRecordsFilterbar.vue';
import JetButton from '@/Jetstream/Button.vue';
import ActivityFilterbar from '@/Pages/Activities/ActivityFilterbar.vue';
import { removeNullValues } from '@/mixins/removeNullValues.js';

export default {
    components: {
        ActivityFilterbar,
        JetButton,
        TestRecordsFilterbar,
        Pagination,
        AppLayout,
        AssetDetailsHeader,
        ActivityLog,
    },

    data() {
        return {
            isLoadingActivities: false,
            activeQueryParams: { sort: { column: 'date', asc: false } },
            fields: [
                {
                    key: 'date',
                    label: this.__('activities.overview.cell.date'),
                    _style: 'width: 120px; min-width: 120px; max-width: 120px',
                },
                {
                    key: 'causer',
                    label: this.__('activities.overview.cell.user'),
                    _style: 'width: 200px; min-width: 200px; max-width: 200px',
                },
                {
                    key: 'event',
                    label: this.__('activities.overview.cell.event'),
                    sorter: false,
                },
            ],
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        activities() {
            return this.$page.props.activities;
        },
    },
    methods: {
        updateSorting(options) {
            this.activeQueryParams.sort = options;
            this.loadActivities();
        },
        updateFilter(filters) {
            this.activeQueryParams = { ...this.activeQueryParams, ...filters };
            this.loadActivities();
        },
        loadActivities() {
            this.isLoadingActivities = true;

            this.$inertia.get(
                this.route('assets.activities.index', this.asset),
                this.getQueryParams(),
                {
                    only: ['activities'],
                    preserveState: true,
                    onFinish: () => {
                        this.isLoadingActivities = false;
                    },
                },
            );
        },
        getQueryParams() {
            let sort = this.activeQueryParams.sort || {
                column: 'date',
                asc: false,
            };

            let data = {
                ...this.activeQueryParams,
                start: this.activeQueryParams.date?.start || null,
                end: this.activeQueryParams.date?.end || null,
                sort: `${sort.column}.${sort.asc ? 'asc' : 'desc'}`,
            };

            delete data.date;
            return removeNullValues(data);
        },
    },
};
</script>
