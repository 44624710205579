export async function fileHash(file) {
    const arrayBuffer = await file.arrayBuffer();

    if (!crypto || !crypto.subtle) {
        throw "Your browser does not support the Web Cryptography API";
    }

    // Use the subtle crypto API to perform a SHA256 Sum of the file's
    // Array Buffer. The resulting hash is stored in an array buffer
    const hashAsArrayBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);

    // To display it as a string we will get the hexadecimal value of
    // each byte of the array buffer. This gets us an array where each byte
    // of the array buffer becomes one item in the array
    const uint8ViewOfHash = new Uint8Array(hashAsArrayBuffer);
    // We then convert it to a regular array so we can convert each item
    // to hexadecimal strings, where characters of 0-9 or a-f represent
    // a number between 0 and 15, containing 4 bits of information,
    // so 2 of them is 8 bits (1 byte).
    return Array.from(uint8ViewOfHash)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
}
