import { Duration } from 'luxon';
import translations from '../../lang/en.json';

export default function (value, locale, format) {
    if (value <= 0) {
        return '00d 00h 00m';
    }

    if (value < 60) {
        return translations['general.less_than_a_minute'] || '< 1min';
    }

    let components = Duration.fromObject({ seconds: value })
        .shiftTo('days', 'hours', 'minutes')
        .toObject();

    // Ignore seconds
    components.days = Math.floor(components.days) || 0;
    components.hours = Math.floor(components.hours) || 0;
    components.minutes = Math.floor(components.minutes) || 0;

    return [
        `${String(components.days).padStart(2, '0')}d`,
        `${String(components.hours).padStart(2, '0')}h`,
        `${String(components.minutes).padStart(2, '0')}m`,
    ].join(' ');
}
