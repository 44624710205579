export function removeNullValues(obj) {
    Object.keys(obj).forEach(
        (key) =>
            (obj[key] &&
                typeof obj[key] === 'object' &&
                removeNullValues(obj[key])) ||
            (!obj[key] && obj[key] === undefined && delete obj[key]) ||
            (!obj[key] && obj[key] === null && delete obj[key]),
    );

    return obj;
}
