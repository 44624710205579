<template>
    <dialog-modal
        v-if="shouldRender && hasAnyPermission('asset.update_status')"
        :is-processing="form.processing"
        :primary-button-label="__('general.action.confirm')"
        :show="show"
        @close="closeModal"
        @submit="handleSubmit"
    >
        <template #title>
            <div>
                {{ __('assets.modal.operability_change.title') }}
            </div>
        </template>

        <div
            v-html="
                __('assets.modal.operability_change.description', {
                    operability: operabilityLabel,
                })
            "
        ></div>
        <form @submit.prevent="handleSubmit">
            <row
                v-if="
                    reasons &&
                    reasons.length &&
                    form.operability === 'inoperable'
                "
                class="mt-3"
            >
                <column>
                    <b>{{
                        __('assets.modal.operability_change.reason.label')
                    }}</b>
                    <input-radio-group
                        v-model="form.reason"
                        :invalid-feedback="form.errors.reason"
                        :options="reasons"
                    />
                </column>
            </row>

            <row class="mt-3">
                <column>
                    <jet-text-area
                        v-model="form.additionalInformation"
                        :horizontal="false"
                        :invalidFeedback="form.errors.additionalInformation"
                        :label="
                            __(
                                'assets.modal.operability_change.additional_information.label',
                            )
                        "
                        :label-classes="{ 'font-weight-bold': true }"
                        :maxlength="80"
                        :placeholder="
                            __(
                                'assets.modal.operability_change.additional_information.placeholder',
                            )
                        "
                        :rows="4"
                    />
                </column>
            </row>
        </form>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetTextArea from '@/Jetstream/Textarea.vue';
import { eventBus, events } from '@/eventBus';

import InputRadioGroup from '@/Components/InputRadioGroup.vue';

export default {
    name: 'ConfirmAssetStatusChangeModal',
    components: {
        InputRadioGroup,

        DialogModal,
        JetButton,
        JetTextArea,
    },
    emits: ['cancel'],
    data() {
        return {
            shouldRender: false,
            show: false,
            assetId: null,
            form: this.$inertia.form({
                operability: null,
                additionalInformation: '',
                reason: 'corrective_maintenance_required',
            }),
        };
    },
    computed: {
        operabilityLabel() {
            const text = this.__(
                `assets.modal.operability_change.label.${this.form.operability}`,
            );
            const cssClass =
                this.form.operability === 'inoperable'
                    ? 'bg-danger'
                    : this.form.operability === 'operable'
                      ? 'bg-success'
                      : 'bg-secondary';

            return `<span><em
            class="operability-indicator ${cssClass} ${this.form.operability}"></em>&nbsp;<b>${text}</b></span>`;
        },
        reasons() {
            return this.$page.props.assetOperabilityChangeReasons.data;
        },
    },
    mounted() {
        eventBus.$on(
            events.openConfirmAssetStatusChangeModal,
            ({ assetId, operability }) => {
                this.assetId = assetId;
                this.form = this.$inertia.form({
                    operability,
                    additionalInformation: '',
                    reason: 'corrective_maintenance_required',
                });

                this.shouldRender = true;
                this.$nextTick(() => (this.show = true));
            },
        );
    },
    beforeUnmount() {
        eventBus.$off(events.openConfirmAssetStatusChangeModal);
    },
    methods: {
        closeModal() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);

            this.$emit('cancel');
        },
        handleSubmit() {
            this.form
                .transform(this.transformData)
                .post(this.route('assets.update-operability', this.assetId), {
                    preserveState: true,
                    onSuccess: () => {
                        eventBus.$emit(events.updateNotifications);

                        eventBus.$emit(events.assetOperabilityUpdated, {
                            asset: this.assetId,
                            ...this.form.data(),
                        });

                        this.closeModal();
                    },
                });
        },
        transformData(data) {
            if (data.operability === 'operable') {
                // POST simple object containing only operability
                return {
                    operability: 'operable',
                    additionalInformation: data.additionalInformation || null,
                };
            }
            return data;
        },
    },
};
</script>
<style scoped>
:deep(textarea) {
    resize: none;
}
</style>
