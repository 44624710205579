<template>
    <c-button
        :class="{ 'btn-icon': !!icon }"
        :color="color"
        :disabled="isProcessing || disabled"
        :shape="shape"
        :type="type"
        :variant="variant"
        style="position: relative; line-height: 28px"
    >
        <icon
            v-if="icon"
            :icon="icon"
            :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
            size="sm"
        />
        <span
            v-else
            :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
            class="d-flex align-items-center text-truncate d-block"
        >
            <slot />
        </span>
        <span
            v-if="isProcessing"
            class="d-flex position-absolute d-flex justify-content-center align-items-center"
            style="top: 0; right: 0; bottom: 0; left: 0"
        >
            <spinner :color="spinnerColor" />
        </span>
    </c-button>
</template>

<script>
import { CButton } from '@coreui/vue/dist/esm/components/button/index.js';

export default {
    name: 'JetButton',
    components: { CButton },

    props: {
        type: {
            type: String,
            default: 'submit',
        },
        color: {
            type: String,
            default: 'primary',
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        shape: {
            type: String,
            default: undefined,
        },
        variant: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
        },
    },

    computed: {
        spinnerColor() {
            if (this.color === 'warning') {
                return '';
            }

            if (!this.variant) {
                return 'light';
            }

            if (this.shape === 'pill') {
                if (this.color === 'danger') {
                    return 'danger';
                }
                return 'primary';
            }
            return 'light';
        },
    },
};
</script>
<style>
.spinner {
    position: absolute;
}
</style>
