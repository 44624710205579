<template>
    <dropdown
        :disabled="disabled"
        :is-processing="isProcessing"
        :toggler-text="selectedUsageCategory?.label"
        class="asset-usage-category"
        color="primary"
    >
        <dropdown-item
            v-for="usageCategory in usageCategories"
            :key="usageCategory.value"
            :class="{
                selected: modelValue === usageCategory.value,
            }"
            class="d-flex justify-content-start align-items-center"
            style="padding-left: 0.5rem !important; gap: 10px"
            @click="updateAssetUsageCategory(usageCategory)"
        >
            <icon
                :style="{
                    opacity: modelValue === usageCategory.value ? 1 : 0,
                }"
                class="text-primary"
                icon="cil-check"
            />
            {{ usageCategory.label }}
        </dropdown-item>
    </dropdown>
</template>
<script>
import DropdownItem from '@/Components/DropdownItem.vue';
import Dropdown from '@/Components/Dropdown.vue';
import Icon from '@/Components/Icon.vue';

export default {
    name: 'usage-category-selection-dropdown',
    components: { Icon, Dropdown, DropdownItem },
    emits: ['update:modelValue'],
    props: {
        disabled: { type: Boolean, default: false },
        modelValue: { type: String },
        usageCategories: { type: Array, default: () => [] },
        isProcessing: { type: Boolean, default: false },
    },
    computed: {
        selectedUsageCategory() {
            return this.usageCategories.find(
                (category) => category.value === this.modelValue,
            );
        },
    },
    methods: {
        updateAssetUsageCategory(item) {
            if (item.value === this.modelValue) {
                return;
            }

            this.$emit('update:modelValue', item.value);
        },
    },
};
</script>
