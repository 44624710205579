<template>
    <form-group
        :horizontal="horizontal"
        :label="label"
        :label-classes="[{ required }, labelClasses]"
    >
        <textarea
            ref="textarea"
            :autofocus="autofocus"
            :class="{ 'is-invalid': invalidFeedback }"
            :placeholder="placeholder"
            :rows="rows"
            :value="modelValue"
            class="form-control"
            @input="valueChanged"
            @keyup="$emit('keyup', $event)"
        />
        <c-form-text v-if="description || maxlength">
            <div class="d-flex justify-content-between">
                <div v-if="description" class="text-muted">
                    {{ description }}
                </div>
                <div v-if="!!maxlength" class="text-muted">
                    {{
                        __('general.remaining_characters', {
                            limit: `${counter}/${maxlength}`,
                        })
                    }}
                </div>
            </div>
        </c-form-text>
        <c-form-feedback :valid="!invalidFeedback"
            ><small class="text-danger">{{
                invalidFeedback
            }}</small></c-form-feedback
        >
    </form-group>
</template>

<script>
import FormGroup from '@/Components/FormGroup.vue';
import { CFormFeedback } from '@coreui/vue-pro/dist/esm/components/form/index.js';

export default {
    name: 'JetTextarea',
    components: { CFormFeedback, FormGroup },

    props: {
        label: String,
        labelClasses: [Object, Array, String],
        type: {
            type: String,
            default: 'text',
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        autocomplete: String,
        modelValue: String,
        horizontal: {
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        rows: {
            type: Number,
            default: 5,
        },
        maxlength: {
            type: Number,
        },
        minHeight: {
            type: Number,
            default: 34,
        },
        scrollIntoView: {
            type: Boolean,
            default: false,
        },
        resizable: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue', 'keyup'],

    data() {
        return {
            counter: 0,
        };
    },

    computed: {
        isValid() {
            return this.invalidFeedback === '' ? null : false;
        },
        element() {
            return this.$refs.textarea;
        },
    },

    mounted() {
        this.counter = this.modelValue?.length ?? 0;

        if (this.resizable) {
            this.resize();
        }

        if (this.scrollIntoView) {
            this.element.addEventListener('focus', this.handleOnFocus);
        }
    },

    beforeDestroy() {
        if (this.scrollIntoView) {
            this.element.removeEventListener('focus', this.handleOnFocus);
        }
    },

    watch: {
        modelValue(newValue) {
            this.$nextTick(() => {
                this.counter = newValue.length ?? 0;
                this.resize();
            });
        },
    },

    methods: {
        focus() {
            this.element.focus();
        },
        valueChanged(event) {
            this.$emit('update:modelValue', event.target.value);
        },
        handleOnFocus(event) {
            event.target.scrollIntoView({ behavior: 'smooth' });
        },
        resize() {
            if (!this.resizable) {
                return;
            }

            let element = this.element;
            let minHeight = this.minHeight * this.rows;

            // Reset to min height
            element.style.height = `${minHeight}px`;

            // Check if size adjustment is necessary
            let height = Math.max(element.scrollHeight, minHeight);
            element.style.height = `${height}px`;
        },
    },
};
</script>
<style scoped>
:deep(textarea) {
    resize: none;
    cursor: text;
    padding: 8px;
}
</style>
