<template>
    <header
        class="d-sm-flex justify-content-between align-items-center pl-sm-3"
    >
        <div
            class="d-flex align-items-center justify-content-between"
            style="gap: 10px"
        >
            <div>
                {{ __('dashboard.asset_management.status.title') }}
            </div>
            <info-icon
                :tooltip="__('dashboard.asset_management.status.tooltip')"
            />
        </div>
        <div class="my-2 my-sm-0">
            <ul
                class="list-unstyled m-0 d-flex justify-content-sm-end"
                style="gap: 7px; overflow: auto"
            >
                <li>
                    <jet-button
                        :disabled="isLoading"
                        :is-processing="isProcessing === 'active'"
                        :variant="
                            isFilterActive('active') ? undefined : 'outline'
                        "
                        class="btn-auto-size"
                        shape="rounded-pill"
                        @click.stop="handleClick('active')"
                    >
                        {{ __('assets.status.active') }}
                    </jet-button>
                </li>
                <li>
                    <jet-button
                        :disabled="isLoading"
                        :is-processing="isProcessing === 'spare'"
                        :variant="
                            isFilterActive('spare') ? undefined : 'outline'
                        "
                        class="btn-auto-size"
                        shape="rounded-pill"
                        @click.stop="handleClick('spare')"
                    >
                        {{ __('assets.status.spare') }}
                    </jet-button>
                </li>
                <li>
                    <jet-button
                        :disabled="isLoading"
                        :is-processing="isProcessing === 'decommissioned'"
                        :variant="
                            isFilterActive('decommissioned')
                                ? undefined
                                : 'outline'
                        "
                        class="btn-auto-size"
                        shape="rounded-pill"
                        @click.stop="handleClick('decommissioned')"
                    >{{ __('assets.status.decommissioned') }}
                    </jet-button>
                </li>
            </ul>
        </div>
    </header>
</template>
<script>
import InfoIcon from '@/Components/InfoIcon.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    name: 'AssetStatusHeader',
    props: {
        activeFilters: {
            type: Array,
            default: () => [],
        },
        isProcessing: {
            type: String,
            default: undefined,
        },
    },
    components: {JetButton, InfoIcon},
    computed: {
        isLoading() {
            return !!this.isProcessing;
        },
    },
    methods: {
        isFilterActive(filter) {
            return this.activeFilters.includes(filter);
        },
        handleClick(filter) {
            this.$emit('filter', filter);
        },
    },
};
</script>
