<template>
    <app-layout>
        <page-header :title="__('stakeholders.overview.title')"></page-header>
        <owners-overview v-if="hasAnyPermission('owner.index')" />
        <operators-overview v-if="hasAnyPermission('operator.index')" />
        <maintainers-overview v-if="hasAnyPermission('maintainer.index')" />
        <delete-stakeholder-modal />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import OwnersTable from '@/Pages/Stakeholders/Owners/OwnersOverview.vue';
import OwnersOverview from '@/Pages/Stakeholders/Owners/OwnersOverview.vue';
import OperatorsOverview from '@/Pages/Stakeholders/Operators/OperatorsOverview.vue';
import MaintainersOverview from '@/Pages/Stakeholders/Maintainers/MaintainersOverview.vue';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    name: 'StakeholdersOverview',
    components: {
        PageHeader,
        DeleteStakeholderModal,
        MaintainersOverview,
        OperatorsOverview,
        OwnersOverview,
        OwnersTable,
        CustomCardHeader,
        AppLayout,
    },
};
</script>
