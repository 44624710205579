<template>
    <app-layout>
        <row>
            <column md="6">
                <jet-form-section @submitted="handleSubmit">
                    <template #title>
                        <icon class="mx-1" icon="cil-cog" />
                        {{ title }}
                    </template>

                    <template #form>
                        <h3>
                            {{
                                __('configurations.asset_configuration.header')
                            }}
                        </h3>
                        <p>
                            {{
                                __(
                                    'configurations.asset_configuration.description',
                                )
                            }}
                        </p>

                        <div class="my-5">
                            <row>
                                <column md="6">
                                    <dropdown-with-action
                                        v-model="form.hardware_version"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.hardware_version
                                        "
                                        :label="
                                            __(
                                                'configurations.field.hardware_version.label',
                                            )
                                        "
                                        :load-on-start="false"
                                        :options="hardware_versions"
                                        :placeholder="
                                            __('general.selection.placeholder')
                                        "
                                        :routes="{
                                            store: route(
                                                'models.hardware-versions.store',
                                                asset.model_id,
                                            ),
                                            index: route(
                                                'models.hardware-versions.index',
                                                asset.model_id,
                                            ),
                                        }"
                                        :showAction="
                                            hasAnyPermission(
                                                'configuration.create',
                                            )
                                        "
                                    />
                                </column>
                                <column md="6">
                                    <dropdown-with-action
                                        v-model="form.software_version"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.software_version
                                        "
                                        :label="
                                            __(
                                                'configurations.field.software_version.label',
                                            )
                                        "
                                        :load-on-start="false"
                                        :options="software_versions"
                                        :placeholder="
                                            __('general.selection.placeholder')
                                        "
                                        :routes="{
                                            store: route(
                                                'models.software-versions.store',
                                                asset.model_id,
                                            ),
                                            index: route(
                                                'models.software-versions.index',
                                                asset.model_id,
                                            ),
                                        }"
                                        :showAction="
                                            hasAnyPermission(
                                                'configuration.create',
                                            )
                                        "
                                    />
                                </column>
                                <column md="6">
                                    <jet-select
                                        v-model="form.conops"
                                        :disabled="conops.length === 0"
                                        :horizontal="false"
                                        :invalidFeedback="form.errors.conops"
                                        :label="
                                            __(
                                                'configurations.field.conops.label',
                                            )
                                        "
                                        :options="conops"
                                        :placeholder="
                                            __(
                                                'configurations.field.conops.placeholder',
                                            )
                                        "
                                    />
                                </column>
                                <column v-if="isSuperAdmin" md="6">
                                    <jet-select
                                        v-model="form.standards"
                                        :disabled="standards.length === 0"
                                        :horizontal="false"
                                        :invalidFeedback="form.errors.standards"
                                        :label="
                                            __(
                                                'configurations.field.standards.label',
                                            )
                                        "
                                        :options="standards"
                                        :placeholder="
                                            __(
                                                'configurations.field.standards.placeholder',
                                            )
                                        "
                                    />
                                </column>
                            </row>

                            <dropdown-with-action
                                v-model="form.detection_algorithms"
                                :horizontal="false"
                                :invalidFeedback="
                                    form.errors.detection_algorithms
                                "
                                :label="
                                    __(
                                        'configurations.field.detection_algorithm.label',
                                    )
                                "
                                :load-on-start="false"
                                :multi-select="true"
                                :options="detectionAlgorithms"
                                :placeholder="
                                    __('general.selection.placeholder')
                                "
                                :routes="{
                                    store: route(
                                        'models.detection-algorithms.store',
                                        asset.model_id,
                                    ),
                                    index: route(
                                        'models.detection-algorithms.index',
                                        asset.model_id,
                                    ),
                                }"
                                :showAction="
                                    hasAnyPermission('configuration.create')
                                "
                            />

                            <row>
                                <column>
                                    <dropdown-with-action
                                        v-model="form.auxiliary_hardware"
                                        :formFields="{
                                            name: undefined,
                                            serialNumber: undefined,
                                        }"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.auxiliary_hardware
                                        "
                                        :label="
                                            __(
                                                'configurations.field.auxiliary_hardware.label',
                                            )
                                        "
                                        :load-on-start="false"
                                        :multi-select="true"
                                        :options="auxiliaryHardware"
                                        :placeholder="
                                            __('general.selection.placeholder')
                                        "
                                        :routes="{
                                            store: route(
                                                'models.auxiliary-hardware.store',
                                                asset.model_id,
                                            ),
                                            index: route(
                                                'models.auxiliary-hardware.index',
                                                asset.model_id,
                                            ),
                                        }"
                                        :showAction="
                                            hasAnyPermission(
                                                'configuration.create',
                                            )
                                        "
                                    >
                                        <template #label="{ option }">
                                            <div
                                                class="d-flex justify-content-between align-items-center"
                                            >
                                                <span class="text-primary">{{
                                                    option.label
                                                }}</span>
                                                <small
                                                    v-if="option.serialNumber"
                                                    class="text-muted"
                                                >
                                                    {{
                                                        option.serialNumber
                                                    }}</small
                                                >
                                            </div>
                                        </template>
                                        <template #form="{ form, label, show }">
                                            <div class="d-flex w-100">
                                                <jet-input
                                                    v-model="form.name"
                                                    :autofocus="show"
                                                    :horizontal="{
                                                        label: 'col-sm-4',
                                                        input: 'col-sm-8',
                                                    }"
                                                    :invalidFeedback="
                                                        form.errors.name
                                                    "
                                                    :label="label"
                                                    :placeholder="label"
                                                    :required="true"
                                                    label-classes="required"
                                                    style="flex-grow: 1"
                                                />
                                            </div>
                                            <div class="d-flex w-100">
                                                <jet-input
                                                    v-model="form.serialNumber"
                                                    :horizontal="{
                                                        label: 'col-sm-4',
                                                        input: 'col-sm-8',
                                                    }"
                                                    :invalidFeedback="
                                                        form.errors.serialNumber
                                                    "
                                                    :label="
                                                        __(
                                                            'general.label.serial_number',
                                                        )
                                                    "
                                                    :placeholder="
                                                        __(
                                                            'general.label.serial_number',
                                                        )
                                                    "
                                                    style="flex-grow: 1"
                                                />
                                            </div>
                                        </template>
                                    </dropdown-with-action>
                                </column>
                            </row>
                        </div>

                        <div>
                            <h3>
                                {{ __('configurations.qualifications.header') }}
                            </h3>
                            <p>
                                {{
                                    __(
                                        'configurations.qualifications.description',
                                    )
                                }}
                            </p>
                        </div>

                        <multi-select-dropdown
                            v-model="form.qualifications"
                            :horizontal="false"
                            :is-form-field="true"
                            :options="qualifications"
                            :placeholder="__('general.selection.placeholder')"
                            :show-select-all-button="false"
                        />
                    </template>

                    <template #actions>
                        <JetFormButtons
                            :cancel-route="$route('assets.show', asset.id)"
                            :disabled="!isFormSubmittable"
                            :is-processing="form.processing"
                            :is-update-form="isUpdateForm"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import DropdownWithAction from '@/Components/DropdownWithAction.vue';
import { route } from 'ziggy-js';
import Row from '@/Components/Row.vue';
import JetInput from '@/Jetstream/Input.vue';

export default {
    components: {
        JetInput,
        Row,
        DropdownWithAction,
        MultiSelectDropdown,
        AppLayout,
        CustomCardHeader,
        JetFormSection,
        JetFormButtons,
        JetSelect,
    },

    data() {
        return {
            form: this.$inertia.form({
                hardware_version: null,
                software_version: null,
                conops: null,
                standards: null,
                detection_algorithms: null,
                auxiliary_hardware: null,
                qualifications: null,
            }),
        };
    },

    computed: {
        isUpdateForm() {
            return !!this.configuration;
        },
        title() {
            return this.__('configurations.header');
        },
        asset() {
            return this.$page.props.asset;
        },
        configuration() {
            return this.$page.props.configuration;
        },
        hardware_versions() {
            return this.$page.props.hardware_versions.data.map((version) => ({
                label: version.name,
                value: version.id,
            }));
        },
        software_versions() {
            return this.$page.props.software_versions.data.map((version) => ({
                label: version.name,
                value: version.id,
            }));
        },
        conops() {
            return this.$page.props.conops;
        },
        standards() {
            return this.$page.props.standards;
        },
        detectionAlgorithms() {
            return this.$page.props.detection_algorithms.data.map(
                (algorithm) => ({
                    label: algorithm.name,
                    value: algorithm.id,
                }),
            );
        },
        auxiliaryHardware() {
            return this.$page.props.auxiliary_hardware.data.map((hardware) => ({
                label: hardware.name,
                value: hardware.id,
                serialNumber: hardware.serialNumber,
            }));
        },
        qualifications() {
            return this.$page.props.qualifications.data;
        },
        nextVersionNumber() {
            return this.configuration ? this.configuration.version + 1 : 1;
        },
        isSuperAdmin() {
            return this.user.roles.includes('super-admin');
        },
    },

    methods: {
        route,
        handleSubmit() {
            if (!this.isFormSubmittable) {
                return;
            }

            if (this.isUpdateForm) {
                this.updateConfiguration();
            } else {
                this.createConfiguration();
            }
        },
        createConfiguration() {
            this.form
                .transform(this.transformData)
                .post(route('assets.configurations.store', this.asset.id));
        },
        updateConfiguration() {
            this.form
                .transform(this.transformData)
                .put(
                    route('assets.configurations.update', [
                        this.asset.id,
                        this.configuration.id,
                    ]),
                );
        },
        transformData(data) {
            return data;
        },
    },

    mounted() {
        if (this.configuration) {
            const {
                hardware_version,
                software_version,
                conops,
                standards,
                detection_algorithms,
                auxiliary_hardware,
                qualifications,
            } = this.configuration;

            this.form = this.$inertia.form({
                hardware_version: hardware_version?.id,
                software_version: software_version?.id,
                conops,
                standards,
                detection_algorithms: detection_algorithms.map(
                    (item) => item.id,
                ),
                auxiliary_hardware: auxiliary_hardware.map((item) => item.id),
                qualifications: qualifications,
            });
        }
    },
};
</script>
