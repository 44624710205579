<template>
    <CCard>
        <CCardBody>
            <jet-section-title>
                <template #title><slot name="title"></slot></template>
                <template #description
                    ><slot name="description"></slot
                ></template>
            </jet-section-title>
            <slot name="content"></slot>
        </CCardBody>
    </CCard>
</template>

<script>
import JetSectionTitle from '@/Jetstream/SectionTitle.vue';

export default {
    name: 'JetActionSection',

    components: {
        JetSectionTitle,
    },
};
</script>
