<template>
    <dropdown
        :key="componentKey"
        :caret="false"
        :class="{ 'opacity-50': disabled }"
        :disabled="disabled"
        :toggler-classes="togglerClasses"
        add-menu-classes="p-0"
        @update:show="isOpen = $event"
    >
        <template #toggler>
            <div
                class="d-flex align-items-center justify-content-between border-secondary rounded w-100 px-2"
                style="cursor: pointer; gap: 10px"
            >
                <div class="text-nowrap">
                    {{ selectedOption?.label || '-' }}
                </div>
                <chevron-down />
            </div>
        </template>
        <div style="max-height: 200px; overflow: auto">
            <dropdown-item
                v-if="resettable"
                :class="labelClasses"
                @click="$emit('update:modelValue', null)"
                >-
            </dropdown-item>
            <dropdown-item
                v-for="option in options"
                :key="option.value"
                :class="labelClasses"
                :disabled="isDisabled(option)"
                @click="$emit('update:modelValue', option.value)"
            >
                {{ option.label }}
            </dropdown-item>
        </div>
    </dropdown>
</template>
<script>
import ChevronDown from '@/../icons/ChevronDown.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownItem from '@/Components/DropdownItem.vue';

export default {
    name: 'SimpleSelect',
    components: { DropdownItem, Dropdown, ChevronDown },
    props: {
        modelValue: {
            type: [String, null, Number],
        },
        options: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        resettable: {
            type: Boolean,
            default: false,
        },
        labelClasses: {
            type: [String, Array],
        },
        min: {
            type: Number,
        },
    },
    data() {
        return {
            componentKey: 0,
            isOpen: false,
        };
    },
    computed: {
        selectedOption() {
            return this.options.find(
                (option) => option.value === this.modelValue,
            );
        },
        togglerClasses() {
            return {
                'border-primary': this.isOpen,
                'border-secondary': !this.isOpen,
                'p-0': true,
                'min-w-0': true,
                rounded: true,
            };
        },
    },
    watch: {
        options: {
            handler: function () {
                this.componentKey += 1;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        isDisabled(option) {
            if (!this.min) {
                return false;
            }

            return (option.numericValue ?? 0) < this.min;
        },
    },
};
</script>
<style scoped>
:deep(.dropdown-menu) {
    min-width: 0;
    width: 100%;
}
</style>
