<template>
    <c-header-toggler
        class="d-flex align-items-center"
        style="margin-inline-start: -14px; color: #fff"
        @click=""
    >
        <icon icon="cil-menu" size="lg" />
    </c-header-toggler>
</template>
<script>
import { CHeaderToggler } from '@coreui/vue/dist/esm/components/header/index.js';

export default {
    name: 'Toggler',
    components: { CHeaderToggler },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
