<template>
    <auth-layout>
        <CContainer class="align-items-center min-vh-100">
            <row class="min-vh-100 justify-content-center align-items-center">
                <column md="6">
                    <card class="p-4 m-0">
                        <c-form @submit.prevent="submit">
                            <h1>{{ __('auth.forgot_password.header') }}</h1>
                            <p class="text-muted">
                                {{ __('auth.confirm_password.description') }}
                            </p>
                            <jet-input
                                id="password"
                                v-model="form.password"
                                :invalidFeedback="form.errors.password"
                                :placeholder="__('auth.general.password')"
                                autocomplete="current-password"
                                required
                                type="password"
                            />
                            <div class="d-flex justify-content-end">
                                <jet-button
                                    :disabled="form.processing"
                                    class="px-4"
                                    color="primary"
                                    type="submit"
                                    >{{
                                        __(
                                            'auth.confirm_password.action.submit',
                                        )
                                    }}
                                </jet-button>
                            </div>
                        </c-form>
                    </card>
                </column>
            </row>
        </CContainer>
    </auth-layout>
</template>

<script>
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';

import Card from '@/Components/Card.vue';

export default {
    components: {
        Card,

        JetButton,
        JetInput,
        JetValidationErrors,
        AuthLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                password: '',
            }),
        };
    },

    methods: {
        submit() {
            this.form.post(this.route('password.confirm'), {
                onFinish: () => this.form.reset(),
            });
        },
    },
};
</script>
