import { DateTime } from 'luxon';

export default function (value, format, locale, timezone) {
    if (!value) return '';

    if (!locale) {
        throw "Parameter 'locale' is missing!";
    }

    if (!format) {
        throw "Parameter 'format' is missing!";
    }

    if (typeof value === 'string') {
        return DateTime.fromISO(value).setLocale(locale).toLocaleString(format);
    } else if (value instanceof DateTime) {
        return value.setLocale(locale).toLocaleString(format);
    } else if (value instanceof Date) {
        return DateTime.fromJSDate(value)
            .setLocale(locale)
            .toLocaleString(format);
    } else {
        return '';
    }
}
