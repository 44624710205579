<template>
    <c-badge :color="color" :text-color="textColor">{{ role.localizedName.toUpperCase() }}</c-badge>
</template>
<script>

import {CBadge} from "@coreui/vue/dist/esm/components/badge/index.js";

export default {
    components: {CBadge},
    props: ['role'],

    computed: {
        color() {
            switch (this.role.name) {
                case 'super-admin':
                    return 'warning';
                case 'admin':
                    return 'primary';
                default:
                    return 'secondary';
            }
        },
        textColor() {
            switch (this.role.name) {
                case 'admin':
                    return 'white';
                default:
                    return 'body-emphasis';
            }
        }
    },
};
</script>
