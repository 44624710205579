import { usePage } from '@inertiajs/vue3';

export const trans = (key, replace = {}, transformer = {}) => {
    let translation = usePage().props.language[key] ?? key;

    replace = replace ?? {};

    // Handle pluralisation
    if (
        typeof translation === 'object' &&
        translation !== null &&
        replace['%d']
    ) {
        const number = replace['%d'];
        if (number === 0) {
            translation = translation.zero ?? translation.other;
        } else if (number === 1) {
            translation = translation.one ?? translation.other;
        } else {
            translation = translation.other;
        }
    }

    Object.keys(replace).forEach((key) => {
        let value = replace[key] ?? '';

        // Pipe the value through the provided transformer
        if (transformer[key] && typeof transformer[key] === 'function') {
            value = transformer[key](value);
        }

        key = key.startsWith('%') ? key : ':' + key;
        translation = translation.replaceAll(key, value);
    });

    // Remove any placeholders without values
    const matches = translation.match(/:[a-z_]+/g) || [];
    matches.forEach((match) => {
        translation = translation.replaceAll(match, '-');
    });

    return translation;
};

export default {
    methods: {
        __: trans,
    },
};
