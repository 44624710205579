<template>
    <div :class="{ 'bg-success': isLoginRoute }" class="min-vh-100">
        <c-header class="justify-content-end" with-subheader>
            <c-header-nav>
                <language-selector />
                <c-nav-item class="d-flex align-items-center">
                    <icon
                        class="mx-4"
                        icon="logoLarge"
                        style="width: 80px; height: 28px"
                    />
                </c-nav-item>
            </c-header-nav>
        </c-header>
        <slot />
    </div>
</template>
<script>
import LanguageSelector from '@/Containers/LanguageSelector.vue';

export default {
    components: { LanguageSelector },
    computed: {
        isLoginRoute() {
            return this.route().current('login');
        },
    },
};
</script>
