<template>
    <div>
        <div v-if="editable">
            <div class="d-flex">
                <div>
                    <b>{{ user.name }}</b>
                </div>
                <div class="mx-2 text-muted">
                    {{ $filters.fullDate(today, currentLocale) }}
                </div>
            </div>
            <jet-textarea
                v-model="form.comment"
                :horizontal="false"
                :inline="true"
                :invalid-feedback="form.errors.comment"
                :placeholder="placeholder"
                :resizable="true"
                :rows="2"
                class="mt-1"
            />
            <div>
                <jet-button
                    :disabled="!form.isDirty"
                    :is-processing="form.processing"
                    class="btn-text"
                    variant="ghost"
                    @click.stop="submitForm"
                    >{{ __('comments.action.post') }}
                </jet-button>
            </div>
            <alert v-if="showWarning" ref="warning" class="mt-3" color="warning"
                >{{ __('comments.warning.unsaved_changes') }}
            </alert>
        </div>
        <div v-if="comments" :key="componentKey" class="mt-3">
            <div v-for="comment in comments" :key="comment.id" class="mb-3">
                <div class="d-flex">
                    <div>
                        <b>{{ comment.author.name }}</b>
                    </div>
                    <div class="mx-2 text-muted">
                        {{
                            $filters.relativeDate(
                                comment.createdAt,
                                currentLocale,
                            )
                        }}
                    </div>
                </div>
                <div v-html="$filters.linkify(comment.comment)" />
            </div>
        </div>
    </div>
</template>
<script>
import JetTextarea from '@/Jetstream/Textarea.vue';
import { DateTime } from 'luxon';
import JetButton from '@/Jetstream/Button.vue';
import { eventBus, events } from '@/eventBus';
import Alert from '@/Components/Alert.vue';
import { $http } from '@/bootstrap.js';

export default {
    name: 'comments',
    components: { Alert, JetButton, JetTextarea },
    props: {
        initialComments: {
            type: Array,
            default: function () {
                return [];
            },
        },
        showWarning: { type: Boolean, default: false },
        placeholder: { type: String },
        editable: { type: Boolean, default: true },
        createCommentUrl: { type: String, required: true },
        getCommentsUrl: { type: String, required: true },
    },
    data() {
        return {
            comments: [],
            componentKey: 0,
            isLoading: false,
            form: this.$inertia.form({
                comment: '',
            }),
        };
    },
    computed: {
        today() {
            return DateTime.fromJSDate(new Date());
        },
    },
    watch: {
        showWarning: function (value) {
            if (value) {
                this.$nextTick(() => {
                    this.$refs.warning.$el.scrollIntoView({
                        behavior: 'smooth',
                    });
                });
            }
        },
    },
    mounted() {
        this.comments = this.initialComments;

        eventBus.$on(events.clockUpdated, () => {
            this.forceRerender();
        });
    },
    beforeUnmount() {
        eventBus.$off(events.clockUpdated);
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
        submitForm() {
            if (!this.isFormSubmittable) {
                return;
            }

            this.form.processing = true;

            $http
                .post(this.createCommentUrl, this.form.data())
                .then((resp) => {
                    this.comments.unshift(resp.data);
                    this.form.reset();
                    this.form.clearErrors();
                    this.$emit('input', this.form.comment);
                })
                .catch((error) => {
                    let errors = {};

                    Object.keys(error.response.data.errors).forEach((key) => {
                        errors[key] = error.response.data.errors[key].join(' ');
                    });

                    this.form.setError(errors);
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
    },
};
</script>
