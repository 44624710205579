<template>
    <div :class="classes" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
    color: {
        type: String,
        default: 'primary',
    },
    size: {
        type: String,
        default: 'sm',
        validate(value) {
            return ['sm', 'md'].includes(value);
        },
    },
});

const classes = computed(() => {
    return ['text-' + props.color, 'spinner-border-' + props.size];
});
</script>
