<template>
    <app-layout>
        <page-header :title="__('settings.information.title')" />
        <div class="row">
            <div class="col-md-6">
                <update-tenant-logo-form />
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import UpdateTenantLogoForm from '@/Pages/Settings/UpdateTenantLogoForm.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    components: {
        PageHeader,
        AppLayout,
        UpdateTenantLogoForm,
    },
};
</script>
