export default {
    computed: {
        isFormSubmittable() {
            if (!this.form) {
                return false;
            }

            if (this.form.processing) {
                return false;
            }

            return this.form.isDirty;
        },
    },
};
