<template>
    <app-layout>
        <h2>Buttons</h2>
        <row>
            <column>
                <jet-button>Test</jet-button>
            </column>
            <column>
                <jet-button :disabled="true">Test</jet-button>
            </column>
            <column>
                <jet-button :is-processing="true">Test</jet-button>
            </column>
            <column>
                <jet-button :is-processing="false" shape="rounded-pill"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button :is-processing="true" shape="rounded-pill"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="false"
                    shape="rounded-pill"
                    variant="outline"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="true"
                    shape="rounded-pill"
                    variant="outline"
                    >Test
                </jet-button>
            </column>
        </row>
        <row class="mt-3">
            <column>
                <jet-button color="warning">Test</jet-button>
            </column>
            <column>
                <jet-button :disabled="true" color="warning">Test</jet-button>
            </column>
            <column>
                <jet-button :is-processing="true" color="warning"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="false"
                    color="warning"
                    shape="rounded-pill"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="true"
                    color="warning"
                    shape="rounded-pill"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="false"
                    color="warning"
                    shape="rounded-pill"
                    variant="outline"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="true"
                    color="warning"
                    shape="rounded-pill"
                    variant="outline"
                    >Test
                </jet-button>
            </column>
        </row>
        <row class="mt-3">
            <column>
                <jet-button color="danger">Test</jet-button>
            </column>
            <column>
                <jet-button :disabled="true" color="danger">Test</jet-button>
            </column>
            <column>
                <jet-button :is-processing="true" color="danger"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="false"
                    color="danger"
                    shape="rounded-pill"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="true"
                    color="danger"
                    shape="rounded-pill"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="false"
                    color="danger"
                    shape="rounded-pill"
                    variant="outline"
                    >Test
                </jet-button>
            </column>
            <column>
                <jet-button
                    :is-processing="true"
                    color="danger"
                    shape="rounded-pill"
                    variant="outline"
                    >Test
                </jet-button>
            </column>
        </row>
    </app-layout>
</template>

<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';

export default {
    components: {
        JetButton,
        AppLayout,
    },
};
</script>
