<template>
    <app-layout>
        <page-header :title="__('locations.overview.title')">
            <template #action>
                <jet-button
                    v-if="hasAnyPermission('location.create')"
                    class="d-sm-none"
                    @click="$inertia.visit(route('locations.create'))"
                >
                    {{ __('locations.overview.action.add_airport') }}
                </jet-button>
            </template>
            <div class="d-flex justify-content-between align-items-start">
                <collapsable-filter-bar
                    :active="activeFilters.isDirty"
                    :is-processing="isProcessing"
                    :show-clear-button="false"
                    class="mt-3 flex-grow-1"
                >
                    <template #visible-filter="{ isProcessing }">
                        <search-filter
                            v-model="activeFilters.search"
                            :is-processing="isProcessing"
                            :placeholder="
                                __('locations.overview.filter.placeholder')
                            "
                            :show-spinner="true"
                        />
                    </template>
                </collapsable-filter-bar>
                <jet-button
                    v-if="hasAnyPermission('location.create')"
                    class="mt-3 d-none d-sm-flex"
                    @click="$inertia.visit(route('locations.create'))"
                >
                    {{ __('locations.overview.action.add_airport') }}
                </jet-button>
            </div>
        </page-header>
        <div class="d-flex justify-content-end">
            <small class="text-muted"
                >{{
                    __('locations.overview.results.count', {
                        count: `${transformedLocations.length}`,
                    })
                }}
            </small>
        </div>
        <data-table
            v-if="topLevelLocations"
            :actions="actions"
            :columns="visibleFields"
            :default-sorting="{ column: 'icaoCode', asc: true }"
            :items="transformedLocations"
            :no-items-view="noItemsView"
            @update:sorter-value="updateSorting"
            @row-clicked="editAirport"
        >
            <template #name="{ item }">
                <td class="align-middle">
                    <div>
                        {{ item.name }}
                    </div>
                    <div class="d-flex align-items-center">
                        <current-time :timezone="item.timezone" />
                    </div>
                </td>
            </template>

            <template #country="{ item }">
                <td class="align-middle">
                    <div class="d-flex align-items-center text-center">
                        <icon
                            v-if="item.countryCodeAlpha2"
                            :name="'cif-' + item.countryCodeAlpha2"
                            class="mx-1"
                        />
                        {{ item.country }}
                    </div>
                </td>
            </template>

            <template #usersCount="{ item }">
                <td class="align-middle">
                    <div class="d-flex align-items-center text-center">
                        <div
                            v-c-tooltip="{
                                content: __(
                                    'locations.overview.cell.users.tooltip.total',
                                ),
                            }"
                        >
                            {{ item.totalUsersCount }}
                        </div>
                    </div>
                </td>
            </template>

            <template #assetsCount="{ item }">
                <td class="align-middle">
                    <div class="d-flex align-items-center text-center">
                        {{ item.assetsCount }}
                    </div>
                </td>
            </template>
        </data-table>
        <pagination :paginator="topLevelLocations" />
        <delete-location-modal />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import CurrentTime from '@/Components/CurrentTime.vue';
import Card from '@/Components/Card.vue';
import DataTable from '@/Components/DataTable.vue';
import PageHeader from '@/Components/PageHeader.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import { useUrlSearchParams } from '@vueuse/core';
import { removeEmptyProperties } from '@/utils.js';
import { eventBus, events } from '@/eventBus.js';
import DeleteLocationModal from '@/Pages/Locations/DeleteLocationModal.vue';

const isNumber = (val) => typeof val === 'number';

export default {
    components: {
        DeleteLocationModal,
        CollapsableFilterBar,
        SearchFilter,
        PageHeader,
        DataTable,
        Card,
        CurrentTime,
        AppLayout,
        CustomCardHeader,
        JetButton,
        Pagination,
    },

    data() {
        return {
            activeQueryParams: { sort: { column: 'name', asc: false } },
            activeFilters: this.$inertia.form({
                search: '',
            }),
            isProcessing: false,
            allFields: [
                {
                    key: 'icaoCode',
                    label: this.__('locations.overview.cell.icao'),
                },
                {
                    key: 'iataCode',
                    label: this.__('locations.overview.cell.iata'),
                },
                {
                    key: 'name',
                    label: this.__('locations.overview.cell.name'),
                },
                {
                    key: 'country',
                    label: this.__('locations.overview.cell.country'),
                },
                {
                    key: 'specificLocationsCount',
                    label: this.__(
                        'locations.overview.cell.specific_locations',
                    ),
                },
                {
                    key: 'usersCount',
                    label: this.__('locations.overview.cell.users'),
                },
                {
                    key: 'assetsCount',
                    label: this.__('locations.overview.cell.assets'),
                },
            ],
            noItemsView: {
                noItems: this.__('locations.overview.results.empty'),
            },
            actions: [
                {
                    permissions: ['location.update'],
                    tooltip: this.__('locations.overview.tooltip.edit_airport'),
                    type: 'primary',
                    click: this.editAirport,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['location.delete'],
                    tooltip: this.__(
                        'locations.overview.tooltip.delete_airport',
                    ),
                    type: 'danger',
                    click: this.confirmDeleteAirport,
                    icon: 'cil-trash',
                },
            ],
        };
    },

    computed: {
        topLevelLocations() {
            return this.$page.props.topLevelLocations;
        },
        visibleFields() {
            return this.allFields;
        },
        transformedLocations() {
            const notAvailable = this.__('general.not_available');

            return this.topLevelLocations.data.map((loc) => ({
                id: loc.id,
                icaoCode: loc.icaoCode || notAvailable,
                iataCode: loc.iataCode || notAvailable,
                name: loc.name || notAvailable,
                country: loc.countryCodeAlpha2 || notAvailable,
                countryCodeAlpha2: loc.countryCodeAlpha2?.toLowerCase(),
                totalActiveUsersCount: isNumber(loc.totalActiveUsersCount)
                    ? loc.totalActiveUsersCount
                    : notAvailable,
                totalUsersCount: isNumber(loc.totalUsersCount)
                    ? loc.totalUsersCount
                    : notAvailable,
                assetsCount: isNumber(loc.assetsCount)
                    ? loc.assetsCount
                    : notAvailable,
                specificLocationsCount: isNumber(loc.specificLocationsCount)
                    ? loc.specificLocationsCount
                    : notAvailable,
                timezone: loc.timezone?.identifier,
            }));
        },
    },

    mounted() {
        const primarySortOption = this.$page.props.sort_order[0];
        this.sortOrder = {
            column: primarySortOption?.column,
            asc: primarySortOption?.direction === 'asc',
        };

        const params = useUrlSearchParams('history');
        this.activeFilters.search = params.search || '';

        this.$watch(
            (vm) => [vm.activeFilters.search],
            () => {
                this.loadLocations();
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },

    methods: {
        loadLocations() {
            this.isProcessing = true;

            this.activeQueryParams = removeEmptyProperties({
                ...this.activeFilters.data(),
                sort: this.sortOrder,
            });

            let sortDirection = this.sortOrder.asc ? 'asc' : 'desc';

            this.$inertia.get(
                this.route('locations.index', {
                    ...this.activeQueryParams,
                    sort: `${this.sortOrder.column}.${sortDirection}`,
                }),
                {},
                {
                    preserveState: true,
                    onFinish: () => {
                        this.isProcessing = false;
                    },
                },
            );
        },
        editAirport(airport) {
            this.$inertia.visit(this.route('locations.edit', airport.id));
        },
        confirmDeleteAirport(airport) {
            eventBus.$emit(events.openDeleteLocationModal, {
                locationId: airport.id,
                name: airport.name,
                type: 'airport',
            });
        },
        getActiveUsers(users) {
            return users?.filter((user) => user.status.value === 'active');
        },
        updateSorting(options) {
            this.sortOrder = options;
            this.loadLocations();
        },
        clearFilters() {
            this.activeFilters.reset();
        },
    },
};
</script>
