<template>
    <c-sidebar-header>
        <slot/>
    </c-sidebar-header>
</template>
<script setup>
import {useSidebarStore} from "@/stores/sidebar.js";
import {CSidebarHeader} from "@coreui/vue/dist/esm/components/sidebar/index.js";

const sidebar = useSidebarStore();
</script>
