<template>
    <collapsable-filter-bar
        :active="activeFilters.isDirty"
        :is-processing="isProcessing"
        @clear="clearFilters"
    >
        <template #visible-filter>
            <toggle-button
                :model-value="activeFilters.type"
                :options="[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                ]"
                class="bg-white"
                style="height: 28px"
                @update:modelValue="updateType"
            />
        </template>
        <search-filter
            v-if="activeFilters.type === 'daily'"
            v-model="activeFilters.search"
            :is-processing="isProcessing"
            :show-spinner="false"
            placeholder="Search for Area, Subarea"
        />

        <location-filter
            :form="activeFilters"
            :show-specific-locations="activeFilters.type === 'daily'"
        />

        <date-filter
            v-model="activeFilters.date"
            :attributes="attributes"
            :disabled-dates="disabledDates"
            :selection-mode="activeFilters.type"
        />
    </collapsable-filter-bar>
</template>
<script>
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import ToggleButton from '@/Components/ToggleButton.vue';
import { DateTime } from 'luxon';
import DateFilter from '@/Pages/ReportingLight/DateFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import JetButton from '@/Jetstream/Button.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';

export default {
    name: 'reporting-filter-bar',
    components: {
        SearchFilter,
        JetButton,
        CollapsableFilterBar,
        DateFilter,
        JetDatepicker,
        LocationFilter,
        ToggleButton,
    },
    emits: ['update'],
    props: {
        isProcessing: { type: Boolean, default: false },
    },
    data() {
        return {
            activeFilters: this.$inertia.form({
                type: 'daily',
                search: undefined,
                locations: [],
                specificLocations: [],
                date: DateTime.now().toFormat('yyyy-MM-dd'),
            }),
        };
    },
    computed: {
        attributes() {
            let attributes = [
                {
                    dot: {
                        style: {
                            'background-color': '#3E56C6',
                        },
                    },
                    popover: {
                        label: 'Report Available',
                        isInteractive: true,
                    },
                    dates: this.availableDates.map((date) => date.end),
                },
            ];

            if (this.activeFilters.type === 'daily') {
                attributes.push({
                    highlight: { class: 'bg-primary', fillMode: 'solid' },
                    dates: new Date(this.activeFilters.date),
                });
            } else {
                attributes.push({
                    highlight: {
                        start: { class: 'bg-primary', fillMode: 'solid' },
                        base: {
                            fillMode: 'solid',
                            class: 'bg-blue-light',
                            contentClass: 'color-text',
                        },
                        end: { class: 'bg-primary', fillMode: 'solid' },
                    },
                    dates: {
                        start: new Date(this.$page.props.report.startDate),
                        end: new Date(this.$page.props.report.endDate),
                    },
                });
            }

            return attributes;
        },
        disabledDates() {
            return this.activeFilters.type === 'daily'
                ? [{ start: DateTime.now().plus({ days: 1 }).toJSDate() }]
                : [{ start: DateTime.now().startOf('week').toJSDate() }];
        },
        availableDates() {
            return this.$page.props.availableReportDates.map((date) => ({
                start: new Date(date.start),
                end: new Date(date.end),
            }));
        },
    },
    mounted() {
        this.readValuesFromUrl();

        this.$watch(
            (vm) => [
                vm.activeFilters.type,
                vm.activeFilters.search,
                vm.activeFilters.locations,
                vm.activeFilters.specificLocations,
                vm.activeFilters.date,
            ],
            () => {
                this.$emit(
                    'update',
                    this.transformData(this.activeFilters.data()),
                );
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },
    methods: {
        transformData(data) {
            let parsedDate = DateTime.fromFormat(data.date || '', 'yyyy-MM-dd');
            if (!parsedDate.isValid) {
                delete data.date;
            }

            if (!data.search) {
                delete data.search;
            }

            return data;
        },
        readValuesFromUrl() {
            let { date, search, type, locations, specificLocations } =
                this.route().params;
            let parsedDate = DateTime.fromFormat(date || '', 'yyyy-MM-dd');
            type = type || 'daily';

            if (!parsedDate.isValid) {
                parsedDate = DateTime.now();
            }

            locations =
                typeof locations === 'string'
                    ? locations.split(',')
                    : Array.isArray(locations)
                      ? locations
                      : [];

            specificLocations =
                typeof specificLocations === 'string'
                    ? specificLocations.split(',')
                    : Array.isArray(specificLocations)
                      ? specificLocations
                      : [];

            this.activeFilters.date = parsedDate.toFormat('yyyy-MM-dd');
            this.activeFilters.search = search;
            this.activeFilters.locations = locations;
            this.activeFilters.specificLocations = specificLocations;
            this.activeFilters.type = type;
        },
        updateType(type) {
            this.activeFilters.type = type;

            let today = DateTime.now();

            if (type === 'weekly') {
                this.activeFilters.search = null;
                this.activeFilters.specificLocations = [];
                this.activeFilters.date = today
                    .endOf('week')
                    .minus({ week: 1 })
                    .toFormat('yyyy-MM-dd');
            } else {
                this.activeFilters.date = today.toFormat('yyyy-MM-dd');
            }
        },
        clearFilters() {
            this.activeFilters.locations = [];
            this.activeFilters.specificLocations = [];
            this.activeFilters.search = null;
            this.updateType(this.activeFilters.type);
        },
    },
};
</script>
