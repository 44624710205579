<template>
    <div class="d-flex flex-column">
        <qrcode-stream
            :class="{ 'border-danger': valid === false }"
            :paused="paused"
            :track="paintBoundingBox"
            class="qrcode-stream-wrapper"
            @detect="onDetect"
            @error="onError"
            @camera-on="onReady"
        >
            <div class="d-flex justify-content-center align-items-center h-100">
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                    style="position: absolute"
                >
                    <div>
                        <icon
                            :class="{ translucent: !isLoading }"
                            class="logo"
                            name="logoSmall"
                            style="width: 100px; height: 100px"
                        />
                    </div>
                </div>

                <div v-if="isLoading" style="position: absolute">
                    <spinner
                        class="m-2"
                        color="primary"
                        style="width: 1.5rem; height: 1.5rem"
                    />
                </div>

                <div
                    v-if="cameraError"
                    class="text-center text-danger"
                    style="position: absolute"
                >
                    <div
                        v-if="cameraError.name === 'NotAllowedError'"
                        v-html="__('general.camera.error.not_allowed')"
                    />
                    <div
                        v-if="cameraError.name === 'StreamLoadTimeoutError'"
                        v-html="__('general.camera.error.timeout')"
                    />
                    <div v-else v-html="__('general.camera.error.generic')" />
                </div>

                <div
                    v-if="isLoading"
                    class="w-100 d-flex justify-content-center align-items-center loading-label"
                >
                    <div>
                        {{ __('qr_code_scanner.loading.label') }}
                    </div>
                </div>
                <div
                    v-if="validationSuccess"
                    class="validation-success d-flex justify-content-center align-items-center"
                >
                    <icon
                        class="text-success"
                        icon="cil-check-circle"
                        style="width: 5rem; height: 5rem"
                    />
                </div>

                <div
                    v-if="validationFailure"
                    class="validation-failure d-flex justify-content-center align-items-center"
                >
                    <icon
                        class="text-danger"
                        icon="cil-ban"
                        style="width: 5rem; height: 5rem"
                    />
                </div>
                <div
                    v-if="validationPending"
                    class="validation-success d-flex justify-content-center align-items-center"
                >
                    <spinner
                        class="m-2"
                        style="width: 1.5rem; height: 1.5rem"
                    />
                </div>
            </div>
        </qrcode-stream>
    </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    props: ['valid'],

    emits: ['success', 'failure', 'reset'],

    components: {
        QrcodeStream,
    },

    data() {
        return {
            result: null,
            isLoading: true,
            cameraError: null,
            paused: false,
        };
    },

    computed: {
        validationPending() {
            return this.valid === undefined && this.paused;
        },

        validationSuccess() {
            return this.valid === true;
        },

        validationFailure() {
            return this.valid === false;
        },
        hasBrowserCompatibility() {
            return !!navigator.mediaDevices;
        },
    },

    methods: {
        onReady(promise) {
            this.isLoading = false;
            this.resetValidationState();
        },
        resetValidationState() {
            this.$emit('reset');
            this.paused = false;
        },
        onDetect([firstDetectedCode]) {
            try {
                const code = this.retrievePetiteCId(firstDetectedCode.rawValue);

                if (!code) {
                    return;
                }

                // Turn camera off
                this.paused = true;

                // Close modal
                this.$emit('success', code);
            } catch (error) {
                // Display errors
                this.qrCodeError = this.__(
                    'qr_code_scanner.modal.error.message',
                );

                this.$emit('failure', error);
            }
        },
        retrievePetiteCId(code) {
            try {
                if (!code) {
                    throw 'qr_code_not_found';
                }

                if (!code.startsWith('"')) {
                    code = '"' + code;
                }

                if (!code.endsWith('"')) {
                    code += '"';
                }

                let codeContainer = JSON.parse(code);

                if (typeof codeContainer === 'string') {
                    codeContainer = JSON.parse(codeContainer);
                }

                if (!codeContainer.uuid) {
                    throw 'qr_code_not_found';
                }

                return codeContainer.uuid;
            } catch {
                return false;
            }
        },
        onError(error) {
            this.isLoading = false;
            this.cameraError = error;

            switch (error.name) {
                case 'NotAllowedError':
                    break;
                default:
                    throw error;
            }
        },

        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const { x, y, width, height } = detectedCode.boundingBox;
                let strokeColor = this.retrievePetiteCId(detectedCode.rawValue)
                    ? '#17FFC6'
                    : '#FF174D';

                ctx.lineWidth = 4;
                ctx.strokeStyle = strokeColor;
                ctx.strokeRect(x, y, width, height);
            }
        },
    },
};
</script>
<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    flex-flow: column nowrap;
}

.logo {
    transition: opacity 1s ease;
}

.translucent {
    opacity: 0;
}

.loading-label {
    margin-top: 150px;
}
</style>
