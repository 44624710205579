<template>
    <app-layout>
        <row>
            <column md="6">
                <jet-form-section @submitted="handleSubmit">
                    <template #title>
                        {{ __('assets.details.edit_asset') }}
                    </template>

                    <template #form>
                        <div>
                            <location-form-fields
                                v-model="form.location_id"
                                :errors="form.errors.location_id"
                            />
                        </div>
                    </template>
                    <template #actions>
                        <jet-form-buttons
                            :cancel-route="route('assets.show', asset.id)"
                            :disabled="!form.isDirty"
                            :is-processing="form.processing"
                            :is-update-form="true"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import LocationFormFields from '@/Pages/Assets/Edit/LocationFormFields.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';

const $page = usePage();
const asset = $page.props.asset;

const form = useForm({
    location_id: asset.location.id || undefined,
});

const handleSubmit = () => {
    form.patch(route('assets.update-location', asset));
};
</script>
