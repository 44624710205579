<template>
    <app-layout>
        <asset-details-header />
        <div class="py-3">
            <TestRecords />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import TestRecords from '@/Components/TestRecords.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';

export default {
    components: {
        AppLayout,
        TestRecords,
        AssetDetailsHeader,
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
};
</script>
