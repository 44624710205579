<template>
    <div v-if="isLoading" class="d-flex justify-content-center mt-4">
        <spinner size="md" />
    </div>
    <div v-else>
        <div v-if="showTitle" class="d-flex align-items-center">
            <h3 class="work-order-title mb-3">{{ title }}</h3>
        </div>
        <work-order-form-asset-details
            v-if="formData?.asset"
            :asset="formData?.asset"
        />
        <form v-if="form">
            <row>
                <column>
                    <jet-select
                        v-model="form.type"
                        :disabled="isTypeForced || isLoading || !types.length"
                        :horizontal="false"
                        :invalid-feedback="form?.errors.type"
                        :label="__('workorder.form.type.label')"
                        :options="types"
                        :placeholder="__('workorder.form.type.placeholder')"
                        :required="true"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-select
                        v-model="form.priority"
                        :disabled="isLoading || !priorities.length"
                        :horizontal="false"
                        :invalid-feedback="form.errors.priority"
                        :label="__('workorder.form.priority.label')"
                        :options="priorities"
                        :placeholder="__('workorder.form.priority.placeholder')"
                        :required="true"
                        default-value="medium"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-select
                        v-model="form.assignee"
                        :disabled="isLoading || !assignees.length"
                        :enable-search="true"
                        :horizontal="false"
                        :invalid-feedback="form.errors.assignee"
                        :label="__('workorder.form.assignee.label')"
                        :options="assignees"
                        :placeholder="__('general.form.select.placeholder')"
                        :resettable="false"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-datepicker
                        v-model="form.startDate"
                        :deletable="true"
                        :horizontal="false"
                        :invalid-feedback="form.errors.startDate"
                        :label="__('workorder.form.start_date.label')"
                        :placeholder="__('general.form.select.placeholder')"
                        @input="onStartDateUpdate"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-datepicker
                        v-model="form.dueDate"
                        :deletable="true"
                        :horizontal="false"
                        :invalid-feedback="form.errors.dueDate"
                        :label="__('workorder.form.due_date.label')"
                        :min-date="form.startDate"
                        :placeholder="__('general.form.select.placeholder')"
                        :required="form.type === 'preventative_maintenance'"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-input
                        v-model="form.title"
                        :horizontal="false"
                        :invalidFeedback="form.errors.title"
                        :label="__('workorder.form.title.label')"
                        :placeholder="__('workorder.form.title.placeholder')"
                        :required="true"
                        icon="cil-qr-code"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-textarea
                        v-model="form.description"
                        :horizontal="false"
                        :invalidFeedback="form.errors.description"
                        :label="__('workorder.form.description.label')"
                        :placeholder="
                            __('workorder.form.description.placeholder')
                        "
                        :resizable="true"
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-input
                        v-model="form.ticketId"
                        :horizontal="false"
                        :info="__('workorder.form.ticket_id.info')"
                        :invalidFeedback="form.errors.tickedId"
                        :label="__('workorder.form.ticket_id.label')"
                        :placeholder="
                            __('workorder.form.ticket_id.placeholder')
                        "
                    />
                </column>
            </row>
            <row>
                <column>
                    <jet-input
                        v-model="form.ticketLink"
                        :horizontal="false"
                        :info="__('workorder.form.ticket_link.info')"
                        :invalidFeedback="form.errors.ticketLink"
                        :label="__('workorder.form.ticket_link.label')"
                        :placeholder="
                            __('workorder.form.ticket_link.placeholder')
                        "
                    />
                </column>
            </row>
            <row>
                <column
                    ><label>Attachment</label>
                    <p>
                        Upload files by dragging them onto the area below or
                        click the "Browse" button to select them.
                    </p>
                    <file-upload
                        v-model="form.attachments"
                        :class="{ 'is-invalid': form.errors.attachments }"
                        @upload-started="$emit('upload-started')"
                        @upload-completed="$emit('upload-completed')"
                    />
                    <div class="invalid-feedback">
                        {{ form.errors.attachments }}
                    </div>
                </column>
            </row>
            <row class="mt-3">
                <column>
                    <small
                        ><em class="text-danger">*</em>
                        {{ __('general.required_fields') }}</small
                    >
                </column>
            </row>
        </form>
    </div>
</template>
<script>
import WorkOrderFormAssetDetails from '@/Pages/WorkOrders/WorkOrderForm/AssetDetails.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetTextarea from '@/Jetstream/Textarea.vue';
import FileUpload from '@/Components/FileUpload.vue';

import { $http } from '@/bootstrap.js';

export default {
    components: {
        FileUpload,
        JetTextarea,
        JetInput,
        JetDatepicker,
        JetSelect,
        WorkOrderFormAssetDetails,
    },
    props: ['assetId', 'form', 'showTitle', 'isTypeForced'],
    data() {
        return {
            isLoading: false,
            formData: null,
            minDueDate: null,
        };
    },
    computed: {
        title() {
            return [
                this.formData?.asset?.type?.value,
                this.formData?.asset?.serial_number,
            ]
                .filter((part) => !!part)
                .join('/');
        },
        types() {
            return this.formData?.types ?? [];
        },
        priorities() {
            return this.formData?.priorities ?? [];
        },
        assignees() {
            return (
                this.formData?.assignees?.map((assignee) => {
                    return {
                        group: assignee.group,
                        data: assignee.data.map((data) => ({
                            label: data.name,
                            value: data.id,
                        })),
                    };
                }) ?? []
            );
        },
    },
    methods: {
        onStartDateUpdate(date) {
            if (date > this.form.dueDate) {
                this.form.dueDate = null;
            }
        },
        loadFormData() {
            if (!this.assetId || this.isLoading) {
                return;
            }

            this.isLoading = true;

            $http
                .get(this.route('assets.workorders.create', this.assetId))
                .then((resp) => {
                    this.formData = resp.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
    watch: {
        assetId: {
            handler: function (newValue, oldValue) {
                if (!!newValue && newValue !== oldValue) {
                    this.loadFormData();
                }
            },
            immediate: true,
        },
    },
};
</script>
<style>
.work-order-title {
    font-size: 15px;
}
</style>
